import awsExports from '../aws-exports';
const rootApiUrl = awsExports.ROOT_API_URL;

export const baseUrl = rootApiUrl;

export const prepareHeaders = (headers, { getState }) => {
        const { user } = getState().User;
        if (user) {
            headers.set('Authorization', user.signInUserSession.accessToken.jwtToken);
        }
        headers.set('Accept', 'application');
        return headers;
    }

