import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders, baseUrl } from './helpers'
import { setDemandS3UrlLoading } from '../redux/slices/demandPdfSlice'
import { set } from 'lodash';

export const pdfApi = createApi({
    reducerPath: 'pdfApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        // GET /document/{documentId}/pdfUrl          
        //gets the (demandS3Url)
        getPdfUrl: builder.query({
            query: (documentId) => ({
                url: `/document/${documentId}/pdfUrl`,
                method: 'GET'
            }),
            // providesTags: (result, error, arg) => [{ type: 'PdfUrl', id: arg }],
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    queryFulfilled
                }
            ) {
                const { user } = getState().User;
                if(!user.isAuthenticated) return;
                setDemandS3UrlLoading({
                    [arg]: true
                });
                try {
                    const success = await queryFulfilled;
                    return success;
                } catch (error) {
                    console.log('error', error);
                }
            }
        }),
        // end getPdfUrl

        // start getPdfRegenerationStatus
        getPdfRegenerationStatus: builder.query({
            query: (documentId) => ({
                url: `/document/${documentId}/documentRegenerate`,
                method: 'GET'
            }),
            async onQueryStarted(
                arg,
                {
                    queryFulfilled,
                    dispatch,
                    getState
                }
            ) {
                dispatch(setDemandS3UrlLoading({
                    [arg]: true  //every docId has a loading state
                }));
                try {
                    const success = await queryFulfilled;
                    const data = success.data;
                    if (data.status === 'Complete') {
                        //if the status is complete, initiate the getPdfUrl query
                        setTimeout(() => {
                        dispatch(
                            pdfApi.endpoints.getPdfUrl.initiate(arg, { forceRefetch: true })
                        )
                        }, 1000);
                        return
                    } else {
                        //else, initiate the getPdfRegenerationStatus query again after 3 seconds
                        setTimeout(() => {
                            dispatch(
                                pdfApi.endpoints.getPdfRegenerationStatus.initiate(arg, { forceRefetch: true })
                            );
                        }, 3000);
                    }
                } catch (error) {
                    console.log('error', error);
                }
            }
        }),


    })
})

export const {
    useGetPdfUrlQuery,
    useGetPdfRegenerationStatusQuery,
    useLazyGetPdfUrlQuery,
    useLazyGetPdfRegenerationStatusQuery,
} = pdfApi

export default pdfApi


