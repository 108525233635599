import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useState, useEffect } from "react";
import { Button, TextField, Divider } from '@mui/material';
import awsExports from '../aws-exports';
import StatusDialog from '../dialogs/StatusDialog';
import PDFViewerComponent from '../CommonComponents/PDFViewerComponent';
import { getDocumentApi } from '../api';

const DemandTemplate = (props) => {

    const { route, signOut, user } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.user
    ]);

    const rootApiUrl = awsExports.ROOT_API_URL;
    const [submitting, setSubmitting] = useState(false);
    const [validInputAvailable, setValidInputAvailable] = useState(false);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [templateText, setTemplateText] = useState("");
    const [jsonBlobText, setJsonBlobText] = useState("");
    const [generatedS3Url, setGeneratedS3Url] = useState("");
    const [errorsList, setErrorsList] = useState([]);
    const [presignedJsonUrl, setPresignedJsonUrl] = useState("");
    const [presignedHtmlUrl, setPresignedHtmlUrl] = useState("");

    useEffect(() => {
        // Update validInputAvailable whenever templateText or jsonBlobText changes
        setValidInputAvailable(templateText.trim() !== '' && jsonBlobText.trim() !== '');
      }, [templateText, jsonBlobText]);

    const submitGenerateDemand = async () => {
        setSubmitting(true);
        showStatusDialog("Generating Demand", "The demand is being templated from the values, please wait...");
        
        // Construct request body for lambda function
        const lambdaRequestBody = {
            template_content: templateText,
            json_content: JSON.parse(jsonBlobText),
            testing: true,
            testingWithAuxiliaryData: true
        };

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(lambdaRequestBody)
        };
        
        const response = await fetch(
            rootApiUrl + "/generateTestDemandPdf", settings
        ).then(
            response => response.json()
        ).then(
            data => {
            setGeneratedS3Url(data['presigned_url']);
            setErrorsList(data['errors']);
            setPresignedJsonUrl(data['presigned_json_url']);
            setPresignedHtmlUrl(data['presigned_html_url']);
            setSubmitting(false);
            setStatusDialogOpen(false);
        }).catch(
            error => {
            console.error("Received error on generateTestDemandPdf:", error);
            setSubmitting(false);
            setStatusDialogText("An error occurred while generating the demand. Please try again.");
            setStatusDialogTitle("Error Generating Demand");
        });
    };

    const handleSaveTemplate = () => {
        // Create a Blob with the template text
        const blob = new Blob([templateText], { type: 'text/html' });
        // Create a temporary anchor element
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'template.html';
        a.click();
    };

    const handleSaveJsonBlob = () => {
        // Create a Blob with the JSON blob text
        const blob = new Blob([jsonBlobText], { type: 'application/json' });
        // Create a temporary anchor element
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'data.json';
        a.click();
    };

    const handleImportDemandJsonBlob = async (demandId) => {
        if (!demandId.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)) { 
            console.error("Invalid demandId provided to handleImportDemandJsonBlob " + demandId);
            return;
        }
    
        showStatusDialog("Importing Demand JSON", "The demand JSON is being imported from ID " + demandId + ", please wait...");
        try {
            const response = await getDocumentApi(demandId, user);
            const responseData = await response.json();
    
            if (responseData.document) {
                const formattedJson = JSON.stringify(responseData.document, null, 4);
                setJsonBlobText(formattedJson);
            }
        } catch (error) {
            console.error("Failed to import demand JSON:", error);
        }
        setStatusDialogOpen(false);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const showStatusDialog = (title, text) => {
        setStatusDialogTitle(title);
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    return (
        <div style={{ paddingTop: '45px', display: 'flex' }}>
            <div style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2%' }}>
                <TextField
                    multiline
                    rows={20}
                    fullWidth
                    label="Paste Template File"
                    variant="outlined"
                    value={templateText}
                    onChange={(e) => setTemplateText(e.target.value)}
                />

                <Divider style={{ width: '100%', marginTop: '10px' }} />

                <TextField
                    multiline
                    rows={8}
                    fullWidth
                    label="Paste JSON Blob, or import from DemandID"
                    variant="outlined"
                    value={jsonBlobText}
                    onChange={(e) => setJsonBlobText(e.target.value)}
                />

                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    <TextField
                        fullWidth
                        label="DemandID to import"
                        variant="outlined"
                        style={{ margin: '10px', marginTop: '16px' }}
                        id="demandId"
                        onChange={(e) => {
                            handleImportDemandJsonBlob(e.target.value);
                        }}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveTemplate}
                        style={{ margin: '10px', fontSize: '10px' }}
                    >
                        Save Template To File
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveJsonBlob}
                        style={{ margin: '10px', fontSize: '10px' }}
                    >
                        Save JSON Blob To File
                    </Button>

                </div>

                <Divider style={{ width: '100%', marginTop: '10px' }} />

                <Button
                    variant="contained"
                    color='secondary'
                    disabled={submitting || !validInputAvailable}
                    onClick={submitGenerateDemand}
                    style={{ margin: '20px', marginBottom: '10px' }}
                >
                    Generate Document
                </Button>

                <Divider style={{ width: '100%', marginTop: '10px' }} />

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                    {presignedJsonUrl ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.open(presignedJsonUrl, '_blank')}
                            style={{ margin: '20px' }}
                        >
                            Download Calculated JSON
                        </Button>
                    ) : null}

                    {presignedHtmlUrl ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.open(presignedHtmlUrl, '_blank')}
                            style={{ margin: '20px' }}
                        >
                            Download Filled Template
                        </Button>
                    ) : null}

                </div>
            </div>

            <Divider orientation="vertical" flexItem />

            <div style={{ width: '50%', padding: '2%'  }}>

                {(errorsList || []).length > 0 ? (
                    <div>
                        <h2>Errors:</h2>
                        <ul>
                            {errorsList.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </div>
                ) : null}

                {generatedS3Url ? (
                    <PDFViewerComponent
                        currentPage={0}
                        height={1000}
                        key={generatedS3Url}
                        demandS3Url={generatedS3Url}
                    />
                ) : (
                    <h1 style={{ textAlign: 'center' }}>The generated document will display here</h1>
                )}
            </div>
            <StatusDialog
                handleClose={handleStatusDialogClose}
                dialogOpen={statusDialogOpen}
                dialogText={statusDialogText}
                dialogTitle={statusDialogTitle}
            />
        </div>
    );
}

export default DemandTemplate;
