import React, { useEffect } from 'react';
import { Box, Stack, IconButton, Skeleton } from '@mui/material';
import Title from './Title';
import { MenuOpenRounded } from '@mui/icons-material';
import PDFViewerComponent from './PDFViewerComponent';
import { Resizable } from 're-resizable';
import { useDispatch, useSelector } from 'react-redux';
import { setPdfPanelOpen, setResizableWidth, setPdfInstance } from '../redux/slices/demandDomSlice';
import moment from 'moment';
import { isDemandS3UrlExpired } from '../common-document';
import pdfApi from '../services/pdf';
import { theme } from '../Theme';
import PDFSkeletonLoader from './PDFSkeletonLoader';

const ResizableWrapWithPdfViewer = ({
  children,
  currentPage = 0,
  currentBoundingBoxes = [],
  currentPageTrigger = 0,
  pdfHeight = '75vh',
  defaultWidth = '66%',
  isPDFSticky = false,
  documentId
}) => {

  const { demandS3UrlLoading, demandS3Url } = useSelector((state) => state.DemandPdf);
  const isPdfLoading = Boolean(demandS3UrlLoading[documentId]);
  //RTK Query
  const currentTime = moment().unix();
  const demandS3Expired = isDemandS3UrlExpired(demandS3Url);

  const dispatch = useDispatch();
  const {
    resizableWidth,
    editAllDocumentsOpen,
    demandDetailsEditOpen,
    demandEditOpen,
    deleteMedicalOpen,
    bulkDeleteOpen,
    bulkEditOpen,
    pdfPanelOpen,
  } = useSelector((state) => state.DemandDom);

  const anyModalsOpen =
    editAllDocumentsOpen || demandDetailsEditOpen || demandEditOpen || deleteMedicalOpen || bulkDeleteOpen || bulkEditOpen;

  useEffect(() => {
    if (demandS3Url && demandS3Expired && !anyModalsOpen) {
      dispatch(
        pdfApi.endpoints.getPdfUrl.initiate(documentId, { forceRefetch: true })
      );
      dispatch(setPdfPanelOpen(true));
    }
  }, [currentTime]);

  useEffect(() => {
    if (!demandS3Url && !demandS3UrlLoading[documentId]) {
      dispatch(
        pdfApi.endpoints.getPdfUrl.initiate(documentId, { forceRefetch: true })
      );
    }
  }, [documentId]);

  useEffect(() => {
    dispatch(setResizableWidth(defaultWidth));
    dispatch(setPdfPanelOpen(true));
    return () => {
      dispatch(setResizableWidth(defaultWidth));
      dispatch(setPdfPanelOpen(true));
    };
  }, [defaultWidth]);

  const handleDocumentPanelClose = (event) => {
    dispatch(setPdfPanelOpen(false));
    setPdfInstance(null);
    dispatch(setResizableWidth('99.9%'));
  };

  const handleDocumentPanelOpen = (event) => {
    dispatch(setPdfPanelOpen(true));
    dispatch(setResizableWidth(defaultWidth));
  };

  const handleResizeStop = (e, direction, ref, d) => {
    const newWidth = resizableWidth + d.width;
    dispatch(setResizableWidth(newWidth));
  };

  const viewerPositioningProps = isPDFSticky
    ? {
      position: 'sticky',
      top: '64px', // Stick to the bottom of the header
      paddingTop: 1, // Align title with form tabs
    }
    : { width: '100%' };

  useEffect(() => {
    if (!pdfPanelOpen) return;

    const divider = document.querySelector(
      'div[style*="position: absolute"][style*="user-select: none"][style*="width: 10px"][style*="cursor: col-resize"][style*="right: -5px;"]'
    );

    const applyDefaultStyles = () => {
      Object.assign(divider.style, {
        backgroundColor: 'rgba(0,0,0,0.12)',
        width: '1px',
        marginRight: '5px',
      })
    }

    if (divider) {
      applyDefaultStyles();
      const handleMouseOver = () =>
        Object.assign(divider.style, {
          backgroundColor: theme.palette.primary.main,
          width: '3px',
          marginRight: '4px',
        });

      const handleMouseOut = applyDefaultStyles;

      divider.addEventListener('mouseover', handleMouseOver);
      divider.addEventListener('mouseout', handleMouseOut);

      return () => {
        divider.removeEventListener('mouseover', handleMouseOver);
        divider.removeEventListener('mouseout', handleMouseOut);
      };
    }


  }, [pdfPanelOpen])

  return (
    <>
      <Resizable
        enable={{ left: pdfPanelOpen, right: pdfPanelOpen }}
        size={{ width: resizableWidth, height: '100%' }}
        minWidth={'25%'}
        maxWidth={!demandS3Url && !isPdfLoading ? '100%' : pdfPanelOpen ? '80%' : '97%'}
        onResizeStop={handleResizeStop}
      >
        {children}
      </Resizable >
      <Box sx={{ width: '100%', paddingLeft: '8px', borderLeft: pdfPanelOpen ? '' : '1px solid', borderColor: 'divider' }}>
        <Box {...viewerPositioningProps}>
          {pdfPanelOpen && (
            <>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 1 }}>
                <Title>Demand</Title>
                <IconButton onClick={handleDocumentPanelClose}>
                  <MenuOpenRounded sx={{
                    transform: 'scaleX(-1)'
                  }} />
                </IconButton>
              </Stack>
              <div>
                {isPdfLoading && (
                  <PDFSkeletonLoader height={pdfHeight} />
                )}

                <Box sx={{
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  overflow: 'hidden',
                  width: '100%',
                  display: isPdfLoading ? 'none' : 'block',
                }}>

                  <PDFViewerComponent
                    currentPage={currentPage}
                    currentBoundingBoxes={currentBoundingBoxes}
                    currentPageTrigger={currentPageTrigger}
                    height={pdfHeight}
                  />
                </Box>

              </div>
            </>
          )}

          {!pdfPanelOpen && (
            <IconButton onClick={handleDocumentPanelOpen}>
              <MenuOpenRounded />
            </IconButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ResizableWrapWithPdfViewer;
