import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { Transition } from '../Transition';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { getDocumentFilesApi } from '../api';
import { setDocumentFiles } from '../redux/slices/demandDomSlice';
import { postIcdCodeUpdates } from '../MedicalsComponents/pageInsights';
import { setDemandS3UrlLoading } from '../redux/slices/demandPdfSlice';
import { setDocumentFilesLoading } from '../redux/slices/documentSlice';
import { setToast } from '../redux/slices/globalToastSlice';
import { setSelectedRowsAndIcdCodeIds } from '../redux/slices/icdCodesSlice';
import ICDCodeAutocomplete from '../MedicalsComponents/ICDCodes/ICDCodeAutocomplete';
import pdfApi from '../services/pdf';

const EditICDCode = () => {
  const dispatch = useDispatch();

  const { documentFiles } = useSelector((state) => state.DemandDom);

  const { editICDCode } = useSelector((state) => state.GlobalDialogues);
  const { open, data } = editICDCode;
  const { documentId, user } = data;

  const { selectedRowsAndIcdCodeIds, aggregatedIcdCodeData } = useSelector((state) => state.IcdCodes);
  const selectedIcdCodes = Object.keys(selectedRowsAndIcdCodeIds);

  const isSameIcdCode = selectedIcdCodes.every((icdCode) => icdCode === selectedIcdCodes[0]);
  const initialIcdCode = isSameIcdCode ? selectedIcdCodes[0] : '';
  const initialDescription = isSameIcdCode ? aggregatedIcdCodeData[selectedIcdCodes[0]].description : '';
  const initialIcdStatus = isSameIcdCode ? aggregatedIcdCodeData[selectedIcdCodes[0]].status : '';

  const [icdCodeFormValue, setIcdCodeFormValue] = useState([initialIcdCode, initialDescription]);
  const [icdCode, description] = icdCodeFormValue;

  const [selectedStatusFormValue, setSelectedStatusFormValue] = useState(initialIcdStatus);
  const [isSaving, setIsSaving] = useState(false);

  const icdCodeDirty = icdCodeFormValue[0] !== initialIcdCode;
  const statusDirty = selectedStatusFormValue !== '';

  // Get all the unique file ids and ICD code ids from the selected rows.
  const selectedIcdCodeIds = Object.values(selectedRowsAndIcdCodeIds).flat();

  const fileIds = [];
  selectedIcdCodes.forEach((icdCode) => {
    aggregatedIcdCodeData[icdCode]?.references.forEach(({ icdCodeId, fileId }) => {
      if (selectedIcdCodeIds.includes(icdCodeId) && !fileIds.includes(fileId)) {
        fileIds.push(fileId);
      }
    });
  });

  const handleIcdCodeChange = (value) => {
    if (Array.isArray(value)) {
      setIcdCodeFormValue(value);
    } else if (value === null) {
      setIcdCodeFormValue(['', '']);
    }
  };

  const handleChangeStatus = (e) => setSelectedStatusFormValue(e.target.value);

  const handleSaveClick = async () => {
    setIsSaving(true);

    dispatch(setDocumentFilesLoading(true)); // Table skeleton
    dispatch(setDemandS3UrlLoading({
      [documentId]: true
    }));

    const filesToUpdate = documentFiles.filter((file) => fileIds.includes(file.fileEntityId));

    postIcdCodeUpdates(
      documentId,
      user,
      filesToUpdate,
      selectedIcdCodeIds,
      selectedStatusFormValue,
      icdCode,
      description
    )
      .then(() => {
        getDocumentFilesApi(documentId, user)
          .then((response) => response.json())
          .then((data) => {
            dispatch(setDocumentFiles(data));
            dispatch(setDocumentFilesLoading(false));
            setIsSaving(false);
          });
        dispatch(
          pdfApi.endpoints.getPdfRegenerationStatus.initiate(documentId, { forceRefetch: true })
        );
      })
      .catch((error) => {
        console.log('error', error);
        dispatch(setDocumentFilesLoading(false));
        dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with updating ICD code(s)' }));
      });

    dispatch(setSelectedRowsAndIcdCodeIds({})); // Reset selections
    dispatch(resetGlobalDialogues()); // Close dialog
  };

  const handleClose = () => {
    setIcdCodeFormValue([initialIcdCode, initialDescription]);
    setSelectedStatusFormValue('');
    dispatch(resetGlobalDialogues());
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth TransitionComponent={Transition}>
      <DialogTitle>Edit ICD codes</DialogTitle>

      <DialogContent>
        <Alert severity="info" spacing={2} sx={{ mb: 2 }}>
          {selectedIcdCodes.length} selected
        </Alert>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <ICDCodeAutocomplete value={icdCodeFormValue} onChange={handleIcdCodeChange} user={user} />

          <FormControl fullWidth>
            <InputLabel id="select-icd-status-label">Status</InputLabel>
            <Select
              labelId="select-icd-status-label"
              id="select-icd-status"
              value={selectedStatusFormValue}
              label="Status"
              onChange={handleChangeStatus}
            >
              <MenuItem value={'INCLUDED'}>Included</MenuItem>
              <MenuItem value={'EXCLUDED'}>Excluded</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>

        <Button
          disabled={!statusDirty && !icdCodeDirty}
          onClick={handleSaveClick}
          variant="contained"
          color="secondary"
        >
          {isSaving ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditICDCode;
