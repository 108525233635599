import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { Transition } from '../Transition';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { getDocumentFilesApi } from '../api';
import { setDocumentFiles } from '../redux/slices/demandDomSlice';
import { selectPageInsightStatusOptions, postMriFindingsUpdates } from '../MedicalsComponents/pageInsights';
import { setDocumentFilesLoading } from '../redux/slices/documentSlice';
import { setDemandS3UrlLoading } from '../redux/slices/demandPdfSlice';
import { setToast } from '../redux/slices/globalToastSlice';
import { fetchDocumentInsightsMriData } from '../redux/thunks/documentInsightsData';
import pdfApi from '../services/pdf';

const EditMRIFinding = () => {
  const initialStatus = '';
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();

  const { editMRIFinding } = useSelector((state) => state.GlobalDialogues);
  const { open, data } = editMRIFinding;
  const { mriSelectedRows, documentId, user } = data;

  const { documentFiles } = useSelector((state) => state.DemandDom);

  const { mriInsightsBody } = useSelector((state) => state.DocumentInsights);

  const isSameDescription = mriSelectedRows.every((row) => row.value === mriSelectedRows[0].value);
  const initialDescription = isSameDescription ? mriSelectedRows[0].value : '';

  const [description, setDescription] = useState(initialDescription);

  const isDescriptionDirty = description !== initialDescription;
  const isStatusDirty = selectedStatus !== initialStatus;

  // Get all the unique file ids and MRI finding ids from the selected rows.
  const { fileIds, mriFindingIds } = mriSelectedRows.reduce(
    (accumulator, row) => {
      const tempFileIds = [];
      const tempMriFindingIds = [];
      row.references.forEach(({ fileId, mriFindingId }) => {
        if (!accumulator.fileIds.includes(fileId)) {
          tempFileIds.push(fileId);
        }
        tempMriFindingIds.push(mriFindingId);
      });

      return {
        fileIds: [...accumulator.fileIds, ...tempFileIds],
        mriFindingIds: [...accumulator.mriFindingIds, ...tempMriFindingIds],
      };
    },
    { fileIds: [], mriFindingIds: [] }
  );

  const handleChangeStatus = (e) => setSelectedStatus(e.target.value);

  const handleChangeDescription = (e) => setDescription(e.target.value);

  const handleSaveClick = () => {
    setIsSaving(true);
    dispatch(setDocumentFilesLoading(true)); // Table skeleton
    dispatch(setDemandS3UrlLoading({
      [documentId]: true
    }));

    const filesToUpdate = documentFiles.filter((file) => fileIds.includes(file.fileEntityId));

    postMriFindingsUpdates(
      filesToUpdate,
      mriInsightsBody,
      mriFindingIds,
      selectedStatus,
      description,
      documentId,
      user
    )
      .then(() => {
        dispatch(fetchDocumentInsightsMriData({ documentId, user }));
        getDocumentFilesApi(documentId, user)
          .then((response) => response.json())
          .then((data) => {
            dispatch(setDocumentFiles(data));
            dispatch(setDocumentFilesLoading(false));
            setIsSaving(false);
          });

        dispatch(
          pdfApi.endpoints.getPdfRegenerationStatus.initiate(documentId, { forceRefetch: true })
        );
      })
      .catch((error) => {
        console.log('error', error);
        dispatch(setDocumentFilesLoading(false));
        dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with updating MRI finding' }));
      });

    dispatch(resetGlobalDialogues());
  };

  const handleClose = () => dispatch(resetGlobalDialogues());

  return (
    <Dialog open={open} onClose={handleClose} fullWidth TransitionComponent={Transition}>
      <DialogTitle>Edit MRI findings</DialogTitle>

      <DialogContent>
        <Alert severity="info" spacing={2} sx={{ mb: 2 }}>
          {mriSelectedRows.length} selected
        </Alert>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            label="Description"
            value={description}
            helperText={description ? '' : 'Multiple values'}
            fullWidth
            multiline
            maxRows={4}
            variant="outlined"
            onChange={handleChangeDescription}
          />

          <FormControl fullWidth>
            <InputLabel id="select-mri-status-label">Status</InputLabel>
            <Select
              labelId="select-mri-status-label"
              id="select-mri-status"
              value={selectedStatus}
              label="Status"
              onChange={handleChangeStatus}
            >
              {selectPageInsightStatusOptions.map(({ value, label }, index) => (
                <MenuItem key={index} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>

        <Button
          disabled={!isDescriptionDirty && !isStatusDirty}
          onClick={handleSaveClick}
          variant="contained"
          color="secondary"
        >
          {isSaving ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMRIFinding;
