import './App.css';
import "./fonts/Rubik-VariableFont_wght.ttf"
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { MedicalsViewer } from './MedicalsComponents/MedicalsViewer';
import { MedicalsAdminViewer } from './MedicalsComponents/MedicalsAdminViewer';
import { MedicalsLawFirmViewer } from './MedicalsComponents/MedicalsLawFirmViewer';
import { ShortCodeResolver } from "./ShortCodeResolver"
import { Error404Page } from "./Error404Page";
import HeaderAppBar from './HeaderAppBar'
import GlobalDialogues from './GlobalDialogues/Index';
import GlobalToasts from './GlobalToasts/Index';
import { Routes, Route, Navigate } from 'react-router-dom';
import React, { } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from './Theme';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import InventoryList from './InventoryList';
import { AdjusterList } from './AdjusterList';
import { AdjusterViewer } from './AdjusterViewer';
import CustomersView from './CustomerManagement/CustomersView';
import { CustomerManagementView } from './CustomerManagement/CustomerManagementView';
import { ContactManagementView } from './ContactManagement/ContactManagement';
import { DefaultRoute } from './DefaultRoute';
import DemandTemplate from './DemandComponents/DemandTemplate';
import ContactLogInventoryView from './ContactLogInventoryView';
import Login from './Authentication/Login';
import RequireAuth from './RequireAuth';
import { LicenseInfo } from '@mui/x-license-pro';
import { LAW_FIRM_ROLES, PRECEDENT_ADMIN_ROLE, PRECEDENT_ROLES } from './common-roles';
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist';
import store from './redux/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import GlobalHooks from './GlobalHooks';

const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY;
if (MUI_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
}
else {
  console.error("MUI_LICENSE_KEY environment variable is not set. Please set it to the license key provided by MUI.")
}
// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
  }
})

export const persistor = persistStore(store)

const logError = (error, info) => {
  console.log("ErrorBoundary caught an error", error, info)
  //do something with the error if needed
}

const App = () => {
  return (
    <Authenticator.Provider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <>
                <HeaderAppBar />
                <ErrorBoundary
                  fallbackRender={ErrorFallback}
                  onError={logError}
                  onReset={() => {
                    // this refreshes the app. option to add custom logic
                  }}
                >
                  <GlobalHooks />
                  <GlobalDialogues />
                  <GlobalToasts />
                  <Routes>
                    <Route path="/" element={<RequireAuth><DefaultRoute /></RequireAuth>} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/m/:documentId" element={<MedicalsViewer />} />
                    <Route path="/a/:documentId" element={<RequireAuth roles={PRECEDENT_ROLES}><MedicalsAdminViewer /></RequireAuth>} />
                    <Route path="/l/:documentId" element={<RequireAuth roles={LAW_FIRM_ROLES}><MedicalsLawFirmViewer /></RequireAuth>} />
                    <Route path={"/404"} element={<Error404Page />} />
                    <Route path="/:shortCode" element={<ShortCodeResolver />} />
                    <Route path="/inventory" element={<RequireAuth roles={[...PRECEDENT_ROLES, ...LAW_FIRM_ROLES]}><InventoryList /></RequireAuth>} />
                    <Route path="/firm-inventory" element={<Navigate replace to="/inventory" />} />
                    <Route path="/adjuster" element={<RequireAuth><AdjusterList /></RequireAuth>} />
                    <Route path="/adjuster/d/:documentId" element={<RequireAuth><AdjusterViewer /></RequireAuth>} />
                    <Route path="/contact-log-inventory" element={<RequireAuth roles={PRECEDENT_ROLES}><ContactLogInventoryView /></RequireAuth>} />
                    <Route path="/customer-management" element={<RequireAuth roles={[PRECEDENT_ADMIN_ROLE]}><CustomersView /></RequireAuth>} />
                    <Route path="/customer-management/:customerId" element={<RequireAuth roles={[PRECEDENT_ADMIN_ROLE]}><CustomerManagementView /></RequireAuth>} />
                    <Route path="/contact-management" element={<RequireAuth roles={[PRECEDENT_ADMIN_ROLE]}><ContactManagementView /></RequireAuth>} />
                    <Route path="/demand-template" element={<RequireAuth roles={[PRECEDENT_ADMIN_ROLE]}><DemandTemplate /></RequireAuth>} />
                  </Routes>
                </ErrorBoundary>
              </>
            </ThemeProvider>
          </LocalizationProvider>
        </PersistGate>
      </Provider>
    </Authenticator.Provider>
  );
}

export default App;
