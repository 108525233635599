import React, { useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
  Paper,
} from '@mui/material';

import { EditOutlined, PictureAsPdfOutlined, TextSnippetOutlined, EditNoteSharp, DriveFileRenameOutline } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setEditAllDocumentsOpen, setDemandDetailsEditOpen, setDocumentFiles } from '../redux/slices/demandDomSlice';
import { setDocumentDataLoading } from '../redux/slices/documentSlice';
import { setEditDemandNotes, setEditDocumentNames } from '../redux/slices/globalDialogueSlice';
import EditDocuments from './EditDocuments';
import EditDemandDetails from './DemandForms/EditDemandDetails';
import AiPromptBuilder from './DemandForms/AiPromptBuilder';
import { fetchDocumentData } from '../redux/thunks/documentData';
import { fetchMedicalsData } from '../redux/thunks/medicalsData';
import awsExports from '../aws-exports';
import { getDocumentFilesApi } from '../api';
import { userHasPermission } from '../common';
import { canComposeDemand } from '../DemandComponents/helpers';
import pdfApi from '../services/pdf';

const EditMenu = ({
  user,
  userData,
  disabled,
  showTooltip,
  documentId,
  isEditDocumentsDisabled = false,
  editDocumentsToolTipText = '',
  useDemandNotesEnabled = false,
}) => {

  //RTK Query
  const {
    demandS3UrlLoading,
    demandS3Url,
  } = useSelector((state) => state.DemandPdf);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');
  const [anchorElOpen, setAnchorElOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const rootApiUrl = awsExports.ROOT_API_URL;
  const { demandDetailsEditOpen, aiPromptBuilderOpen } = useSelector((state) => state.DemandDom);
  const { documentMetaData } = useSelector((state) => state.Document);


  const handleMenuOpen = (e) => setAnchorElOpen(e.currentTarget);

  const handleClose = () => setAnchorElOpen(false);

  const handleDocumentsEditOpen = () => {
    setAnchorElOpen(false);
    dispatch(setEditAllDocumentsOpen(true));
  };

  const handleDemandLetterEditOpen = () => {
    setAnchorElOpen(false);
    dispatch(setDemandDetailsEditOpen(true));
  };

  const handleOpenEditNotes = () => {
    setAnchorElOpen(false);
    dispatch(setEditDemandNotes({ open: true, isOpenFromInventory: false, data: { documentId, user } }));
  };

  const handleEditDocumentNames = () => {
    setAnchorElOpen(false);
    dispatch(setEditDocumentNames({ open: true, data: { documentId, user } }));
  };

  const handleToastClose = () => setToastOpen(false);

  const saveDocument = async (showSaveDialog, document) => {
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: user.signInUserSession.accessToken.jwtToken,
      },
      body: JSON.stringify(document),
    };

    setIsSaving(true);
    fetch(rootApiUrl + '/document', settings)
      .then((response) => {
        if (response.ok) {
          dispatch(setDocumentDataLoading(documentId));
          setIsSaving(false);
          dispatch(fetchDocumentData({ documentId, user }));
          dispatch(fetchMedicalsData({ documentId, user }));
          setToastMessage('Demand updated successfully');
          setToastOpen(true);
          return response.json();
        } else {
          setIsSaving(false);
          throw new Error('Server returned ' + response.status + ' status code. ');
        }
      })
      .then((resp) => {
        if (resp) {
          dispatch(
            pdfApi.endpoints.getPdfRegenerationStatus.initiate(documentId, { forceRefetch: true })
          );
          dispatch(fetchMedicalsData({ documentId, user }));
          getDocumentFilesApi(documentId, user)
            .then((response) => response.json())
            .then((data) => {
              dispatch(setDocumentFiles(data));
            })
            .catch((error) => console.log('error', error));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setDocumentDataLoading(''));
        setIsSaving(false);
        setToastMessage('Error updating demand');
        setToastSeverity('error');
        setToastOpen(true);
      });
  };

  return (
    <div>
      <EditDocuments user={user} userData={userData} documentId={documentId} handleSave={saveDocument} />

      {demandDetailsEditOpen && userHasPermission('DemandCompose', userData) && (
        <EditDemandDetails
          editOverviewOpen={demandDetailsEditOpen}
          handleSave={saveDocument}
          user={user}
          userData={userData}
        />
      )}
      {aiPromptBuilderOpen && (
        <AiPromptBuilder
          promptBuilderOpen={aiPromptBuilderOpen}
          user={user}
          userData={userData}
          documentData={documentMetaData}
          demandPDFUrl={demandS3Url}
        />
      )}

      <Tooltip
        title={
          disabled && showTooltip
            ? 'This demand has been approved for delivery or archived. It can no longer be edited.'
            : ''
        }
        arrow
      >
        <div>
          <Button
            id="basic-button"
            aria-controls={anchorElOpen ? 'basic-menu' : undefined}
            aria-haspopup={anchorElOpen ? 'true' : undefined}
            aria-expanded={anchorElOpen ? 'true' : undefined}
            onClick={handleMenuOpen}
            color="secondary"
            variant="outlined"
            startIcon={<EditOutlined />}
            disabled={disabled}
          >
            Edit
          </Button>
        </div>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorElOpen}
        open={!!anchorElOpen}
        onClose={handleClose}
        disableScrollLock
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {canComposeDemand(
          userData,
          documentMetaData
        ) && // Use an array because MUI complains if a fragment is used as a child of Menu
          [
            <MenuItem key="demand-details" onClick={handleDemandLetterEditOpen}>
              <ListItemIcon>
                <TextSnippetOutlined />
              </ListItemIcon>
              <ListItemText primary="Demand details" />
            </MenuItem>,

            <Tooltip
              key="documents"
              title={isEditDocumentsDisabled ? editDocumentsToolTipText : ''}
              arrow
              placement="left"
            >
              <div>
                <MenuItem
                  onClick={handleDocumentsEditOpen}
                  disabled={isEditDocumentsDisabled}
                >
                  <ListItemIcon>
                    <PictureAsPdfOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Documents" />
                </MenuItem>
              </div>
            </Tooltip>,

            <MenuItem
              onClick={handleEditDocumentNames}
            >
              <ListItemIcon>
                <DriveFileRenameOutline />
              </ListItemIcon>
              <ListItemText primary="Document display names" />
            </MenuItem>,
          ]}

        {useDemandNotesEnabled && (
          <MenuItem onClick={handleOpenEditNotes} disabled={!userHasPermission('DemandCompose', userData)}>
            <ListItemIcon>
              <EditNoteSharp />
            </ListItemIcon>
            <ListItemText primary="Notes" />
          </MenuItem>
        )}
      </Menu>
      <Snackbar
        open={toastOpen}
        autoHideDuration={2000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Paper elevation={8}>
          <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
            {toastMessage}
          </Alert>
        </Paper>
      </Snackbar>

      <Dialog open={isSaving} fullScreen={true} fullWidth={true}>
        <DialogContent>
          <CircularProgress size={100} sx={{ display: 'block', margin: 'auto', marginTop: '200px' }} />
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Saving...
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditMenu;
