import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDocumentApi, regenerateDocumentStatusApi } from '../../api';
import { setDocumentDataLoading } from '../slices/documentSlice';
import { setToast } from '../slices/globalToastSlice';
import { setDemandS3UrlLoading } from '../slices/demandPdfSlice';
import pdfApi from '../../services/pdf';

export const fetchDocumentData = createAsyncThunk('Document/fetchDocumentData', async ({ documentId, user }, { }) => {
  const response = await getDocumentApi(documentId, user).then((serverResponse) => {
    if (serverResponse.status === 200) {
      return serverResponse.json();
    } else if (serverResponse.status === 403) {
      return false;
    }
  });

  return response;
});

export const checkRegeneratePdfStatus = (documentId, user) => (dispatch) => {
  const recursivelyCheckGeneratedPdfStatus = (documentId, user) => {
    regenerateDocumentStatusApi(documentId, user)
      .then((response) => {
        if (response?.status === 'DocumentRegeneration' || response?.status === 'InProcess') {
          setTimeout(() => {
            recursivelyCheckGeneratedPdfStatus(documentId, user);
          }, 3000);
        } else {
          dispatch(setDocumentDataLoading(documentId));
          dispatch(fetchDocumentData({ documentId, user }));
          dispatch(
            pdfApi.endpoints.getPdfUrl.initiate(documentId, { forceRefetch: true })
          );
          // Done processing
          return response
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error updating demand' }));
      });
  }

  dispatch(setDemandS3UrlLoading({
    [documentId]: true
  }));
  recursivelyCheckGeneratedPdfStatus(documentId, user);
};

