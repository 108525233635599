import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders, baseUrl } from './helpers'

export const medicalsApi = createApi({
  reducerPath: 'medicalsApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  }),
  tagTypes: ['Medicals'],
  endpoints: (builder) => ({
    postMedsToFile: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/file/${id}/medicals`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: [{ type: 'Medicals', id: arg => arg.id }],  // Invalidate the Medicals tag when this mutation is called, automatically refetching any queries that depend on it
      async onQueryStarted(
        arg,
        {
          dispatch,
          queryFulfilled
        }
      ) {
        try {
          await queryFulfilled;
        } catch (error) {
        }
      },
    }),

    //GET /document/{documentId}/medicals
    getMedicals: builder.query({

      query: (documentId) => ({
        url: `/document/${documentId}/medicals`,
        method: 'GET'
      }),
      providesTags: (result, error, arg) => [{ type: 'Medicals', id: arg }],

      // transformResponse: (response, meta, arg) => response.data,
      // => transform the response to be more useful

      // transformErrorResponse: (response, meta, arg) => response.status,
      // => Pick out errors and prevent nested properties in a hook or selector



      // the destructured `QueryLifecycleApi`
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }
      ) {

        try {
          await queryFulfilled;
        } catch (error) {
        }

      },
      // the destructured `QueryCacheLifecycleApi`
      async onCacheEntryAdded(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          cacheEntryRemoved,
          cacheDataLoaded,
          getCacheEntry,
          updateCachedData,
        }
      ) {

        console.log('Cache entry added', cacheDataLoaded);
        //custom functionality goes here

      },

    }),

  }),
})

export const {
  useGetMedicalsQuery,
  usePostMedsToFileMutation
} = medicalsApi;

export default medicalsApi;