import { createSlice } from '@reduxjs/toolkit';
import { fetchDocumentData } from '../thunks/documentData';

const originalDocumentState = {
  documentData: null,
  documentMetaData: {},
  documentStatus: '',
  deliveryAttorneyEmail: '',
  deliveryCarrierEmail: '',
  deliveryCarrierFax: '',
  commonCarrierName: '',
  noPermissions: false,
  documentDataLoading: '',
  documentFilesLoading: false,
  shouldDeleteFile: false,
  batches: [],
};

const documentSlice = createSlice({
  name: 'Document',
  initialState: originalDocumentState,
  reducers: {
    setDocumentData: (state, action) => {
      state.documentData = action.payload;
    },
    setDocumentMetaData: (state, action) => {
      state.documentMetaData = action.payload;
    },
    setDocumentStatus: (state, action) => {
      state.documentStatus = action.payload;
    },
    setDeliveryAttorneyEmail: (state, action) => {
      state.deliveryAttorneyEmail = action.payload;
    },
    setCommonCarrierName: (state, action) => {
      state.commonCarrierName = action.payload;
    },
    setCarrierEmailAddress: (state, action) => {
      state.carrierEmailAddress = action.payload;
    },
    setDeliveryCarrierFax: (state, action) => {
      state.deliveryCarrierFax = action.payload;
    },
    setNoPermissions: (state, action) => {
      state.noPermissions = action.payload;
    },
    setDocumentDataLoading: (state, action) => {
      state.documentDataLoading = action.payload;
    },
    clearDocumentState: () => originalDocumentState,
    setDocumentFilesLoading: (state, action) => {
      state.documentFilesLoading = action.payload;
    },
    setShouldDeleteFile: (state, action) => {
      state.shouldDeleteFile = action.payload;
    },
    setBatches: (state, action) => {
      state.batches = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentData.fulfilled, (state, action) => {
      if (action.payload) {
        state.documentData = action.payload.document;
        state.documentMetaData = action.payload.metadata;
        state.documentStatus = action.payload.metadata.documentStatus;
        state.deliveryAttorneyEmail = action.payload.deliveryAttorneyEmail;
        state.deliveryEmail = action.payload.deliveryEmail;
        state.deliveryFax = action.payload.deliveryFax;
        state.carrierEmailAddress = action.payload.deliveryEmail;
        state.carrierFaxNumber = action.payload.deliveryFax;
        state.noPermissions = false;
        state.documentDataLoading = '';
      } else {
        state.noPermissions = true;
        state.documentDataLoading = '';
      }
    });
    builder.addCase(fetchDocumentData.pending, (state, action) => {
      state.documentDataLoading = action.meta.arg.documentId;
    });
    
  },
});

export const {
  setDocumentData,
  setDocumentMetaData,
  setDocumentStatus,
  setDeliveryEmail,
  setDeliveryFax,
  setCarrierEmailAddress,
  setCarrierFaxNumber,
  setNoPermissions,
  setDocumentDataLoading,
  clearDocumentState,
  setDocumentFilesLoading,
  setShouldDeleteFile,
  setBatches,
} = documentSlice.actions;

export default documentSlice.reducer;
