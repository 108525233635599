import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import inventoryReducer from './slices/inventorySlice';
import contactLogInventoryReducer from './slices/contactLogInventorySlice';
import medicalsSlice from './slices/medicalsSlice';
import medicalsTreatmentsReducer from './slices/medicalsTreatmentsSlice';
import navbarSlice from './slices/navbarSlice';
import authorSlice from './slices/authorSlice';
import demandDomSlice from './slices/demandDomSlice';
import documentSlice from './slices/documentSlice';
import userHistoryPersistedSlice from './persistedSlices/userHistoryPersistedSlice';
import globalDialogueSlice from './slices/globalDialogueSlice';
import globalToastSlice from './slices/globalToastSlice';
import customerSlice from './slices/customerSlice';
import documentInsightsSlice from './slices/documentInsightsSlice';
import templateSlice from './slices/templateSlice';
import mriFindingsSlice from './slices/mriFindingsSlice';
import sideBySideWithVerticalNavSlice from './slices/sideBySideWithVerticalNavSlice';
import icdCodesSlice from './slices/icdCodesSlice';
import aiNarrativeGenerationSlice from "./slices/aiNarrativeGenerationSlice";
import adminEditTemplateSlice from "./slices/adminEditTemplateSlice";
import templatePreviewSlice from './slices/templatePreviewSlice';
import codeEditorSlice from './slices/codeEditorSlice';
import userSlice from './slices/userSlice';
import demandPdfSlice from './slices/demandPdfSlice';

//RTK Query
import { medicalsApi } from '../services/medicals';  // Import the medicalsApi object from the RTK Query service file
// import _medicalsSlice from './slices/_medicalsSlice';  // Import the custom slice that listens for RTK Query actions
import { pdfApi } from '../services/pdf';

const getStore = () => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['UserHistoryPersisted'],
  };

  const rootReducer = combineReducers({
    Inventory: inventoryReducer,
    ContactLogInventory: contactLogInventoryReducer,
    MedicalsTreatments: medicalsTreatmentsReducer,
    Medicals: medicalsSlice,
    Navbar: navbarSlice,
    Author: authorSlice,
    DemandDom: demandDomSlice,
    Document: documentSlice,
    GlobalDialogues: globalDialogueSlice,
    GlobalToasts: globalToastSlice,
    UserHistoryPersisted: userHistoryPersistedSlice,
    Customer: customerSlice,
    DocumentInsights: documentInsightsSlice,
    Template: templateSlice,
    MriFindings: mriFindingsSlice,
    IcdCodes: icdCodesSlice,
    AiNarrativeGeneration: aiNarrativeGenerationSlice,
    SideBySideWithVerticalNav: sideBySideWithVerticalNavSlice,
    AdminEditTemplate: adminEditTemplateSlice,
    TemplatePreview: templatePreviewSlice,
    CodeEditor: codeEditorSlice,
    User: userSlice,
    DemandPdf: demandPdfSlice,
    [medicalsApi.reducerPath]: medicalsApi.reducer,  // Add the medicalsApi to the rootReducer
    [pdfApi.reducerPath]: pdfApi.reducer
    // _MedicalsSlice: _medicalsSlice, //  Not required for RTK Query, but can be used to create custom reducers and listeners for RTK API's
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  return configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(
          medicalsApi.middleware,  // Add the medicalsApi middleware to the store
          pdfApi.middleware
        ),
  });
};

const store = getStore();

setupListeners(store.dispatch)

export default store;