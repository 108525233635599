import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { Autocomplete, Stack, Snackbar, Alert, Button, IconButton, Typography, InputAdornment, TextField, Switch, FormControlLabel } from '@mui/material';
import awsExports from '../aws-exports';
import { isValidEmail } from '../common';
import { LAW_FIRM_ADMIN_ROLE} from '../common-roles';
import { AlternateEmail, EditOutlined, SaveRounded, CloseRounded, DeleteRounded, ContactMailOutlined } from '@mui/icons-material';
import StatusDialog from '../dialogs/StatusDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

const CustomerConfigView = ({updateCallback, user}) => {
    const AdminEditTemplateState = useSelector((state) => state.AdminEditTemplate);
    const precedentUsers = AdminEditTemplateState.precedentUsers;
    const selectedCustomer = AdminEditTemplateState.customerData;
    
    const customerTypes=[
        {
            value: "lawfirm",
            label: "Law Firm"
        }
    ];

    const newApproverRoles=[
        {
            value: LAW_FIRM_ADMIN_ROLE,
            label: "Law Firm Admin"
        }
    ];

    const navigate = useNavigate();
    const isNewCustomer = selectedCustomer["isNew"];

    const rootApiUrl = awsExports.ROOT_API_URL;
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastSeverity, setToastSeverity] = useState();
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [confirmationDialogText, setConfirmationDialogText] = useState("");
    const [confirmationDialogTitle, setConfirmationDialogTitle] = useState("");
    const [saveConfirmationDialogOpen, setSaveConfirmationDialogOpen] = useState(false);
    const [saveConfirmationDialogText, setSaveConfirmationDialogText] = useState("");
    const [saveConfirmationDialogTitle, setSaveConfirmationDialogTitle] = useState("");
    const [adminUsers, setAdminUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

     // Customer form state
     const defaultFormData = {};
     const [formData, setFormData] = useState(defaultFormData);
     const [orignalFormData, setOrignalFormData] = useState(defaultFormData);
     const [formError, setFormError] = useState({});
     const [formDirty, setFormDirty] = useState(false);
     const [formValid, setFormValid] = useState(true);

    const saveCustomerApi = async (carrier) => {

        setSaving(true);

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(carrier)
        };

        const response = await fetch(
            rootApiUrl + "/customer", settings
        ).then((response) => response)
            .finally(() => {
                setSaving(false);
            });
        
        if (response.status == 200) {
            setEditMode(false);
            showToast(`${selectedCustomer.name} saved successfully`, "success");
            updateCallback(selectedCustomer.customerId);
        }
        else {
            showStatusDialog("Error Saving Customer", "There was an error saving the cusomer data.");
        }
    };

    /*
     * Helpers
     */ 

    
    const mapCustomerTypeToLabel = (customerType) => {
        if (customerType == "lawfirm")
            return "Law Firm";
        else
            return customerType;
    };
    
    const convertMinutesToHours = (minutes) => {
        return minutes/60;
    };

    const findUser = (emailAddress, userList) => {
        return userList.find(element => element.value == emailAddress);
    };

    const getCreateDemandLabelText = (canCreateDemand) => {
        return canCreateDemand == 0 ? "Cannot create demand" : "Can create demand"
    };

    const getUseNotesLabelText = (canUseNotes) => {
        return canUseNotes == 0 ? "Not using notes" : "Using notes"
    };

    const getDisplayMriFindingsLabelText = (displayMriFindings) => displayMriFindings ? 'Show MRI findings' : 'Hide MRI findings';

    const isEmailDomainInUse = (domain) => {
        const matched = allUsers.find(element => element.emailAddress.endsWith(`@${domain}`));
        return matched;
    };

    const isFirmNameValid = (firmName) => {
        const emailRegex = /^[ A-Za-z0-9~!$%^&*_=+}{'?\-.]*$/;
        return emailRegex.test(firmName);
    };

   
    /*
     * Form
     */

    const mapServerDataToForm = () => {
        const localFormData = {};

        localFormData["name"] = selectedCustomer.name;
        localFormData["customerType"] = {value: selectedCustomer.customerType, label: mapCustomerTypeToLabel(selectedCustomer.customerType) };
        localFormData["customerShortLabel"] = selectedCustomer.customerShortLabel;
        localFormData["tin"] = selectedCustomer.customerEntityData?.tin;
        localFormData["customMessage"] = selectedCustomer.customerEntityData?.deliveryNotificationCustomMessage;
        localFormData["createDemandEnabled"] = selectedCustomer.customerEntityData?.createDemandEnabled;
        localFormData["useDemandNotesEnabled"] = selectedCustomer.customerEntityData?.useDemandNotesEnabled ?? false;
        localFormData["displayMriFindings"] = selectedCustomer?.displayMriFindings;
        localFormData["litifyUrl"] = selectedCustomer.customerEntityData?.litifyUrl;

        if (isNewCustomer) {
            localFormData["defaultApproverRole"] = newApproverRoles[0]; 
        }

        if (selectedCustomer.ownerId) {
            const user = findUser(selectedCustomer.ownerId, precedentUsers);
            localFormData["owner"] = user;
        }

        if (selectedCustomer.customerEntityData?.defaultFirmApprovalEmail && adminUsers) {
            const user = findUser(selectedCustomer.customerEntityData?.defaultFirmApprovalEmail, adminUsers);
            localFormData["defaultApprover"] = user;
        }

        if (selectedCustomer.customerEntityData?.validEmailDomains) {
            localFormData["validEmailDomains"] = [...selectedCustomer.customerEntityData?.validEmailDomains];
        }
        else {
            localFormData["validEmailDomains"] = [""];
        }

        localFormData["approvalCCs"] = [];
        if (selectedCustomer.customerEntityData?.firmApprovalCC) {
            selectedCustomer.customerEntityData.firmApprovalCC.map(
                (email) => localFormData["approvalCCs"].push(findUser(email, adminUsers))
            );
        }

        if (selectedCustomer.firmApprovalRemindersConfig && selectedCustomer.firmApprovalRemindersConfig.length > 0) {
            const firstReminder = findReminderConfig("first_reminder", selectedCustomer);
            if (firstReminder) {
                localFormData["firstReminderTime"] = firstReminder["minutesFromStart"];
            }

            const secondReminder = findReminderConfig("second_reminder", selectedCustomer);
            if (secondReminder) {
                localFormData["secondReminderTime"] = secondReminder["minutesFromStart"];
            }

            const thirdReminder = findReminderConfig("third_reminder", selectedCustomer);
            if (thirdReminder) {
                localFormData["thirdReminderTime"] = thirdReminder["minutesFromStart"];
            }
        }

        setOrignalFormData({...localFormData});
        setFormData({...localFormData});

    };

    const mapFormDataToServer = () => {
        const serverData = cloneDeep(selectedCustomer);
        
        serverData["name"] = formData["name"];
        serverData["customerType"] = isNewCustomer ? getFormData("customerType").value : serverData.customerType;
        serverData["customerShortLabel"] = formData["customerShortLabel"] || "";
        serverData["customerEntityData"]["tin"] = formData["tin"];
        serverData["customerEntityData"]["deliveryNotificationCustomMessage"] = formData["customMessage"];
        serverData["customerEntityData"]["createDemandEnabled"] = formData["createDemandEnabled"] ? 1 : 0;
        serverData["customerEntityData"]["useDemandNotesEnabled"] = formData["useDemandNotesEnabled"] ? 1 : 0;
        serverData["customerEntityData"]["displayMriFindings"] = formData["displayMriFindings"];
        serverData["customerEntityData"]["litifyUrl"] = formData["litifyUrl"];
        serverData["isNewCustomer"] = isNewCustomer;
        if (formData["owner"]) {
            serverData["ownerId"] = formData["owner"].value;
        }

        if (isNewCustomer) {
            serverData["defaultFirmApprovalRole"]  = formData["defaultApproverRole"].value;
            serverData["defaultFirmApprovalFirstName"]  = formData["defaultApproverFirstName"]
            serverData["defaultFirmApprovalLastName"]  = formData["defaultApproverLastName"]
            serverData["customerEntityData"]["defaultFirmApprovalEmail"] = formData["defaultApproverEmail"];
        }
        else {
            if (formData["defaultApprover"]) {
                serverData["customerEntityData"]["defaultFirmApprovalEmail"] = formData["defaultApprover"].value;
            }
        }

        if (formData["validEmailDomains"]) {
            serverData["customerEntityData"]["validEmailDomains"] = [...formData["validEmailDomains"]];
        }

        if (formData["approvalCCs"]) {
            serverData["customerEntityData"]["firmApprovalCC"] = [];
            formData["approvalCCs"].map((user)=> serverData["customerEntityData"]["firmApprovalCC"].push(user.value));
        }

        serverData["firmApprovalRemindersConfig"] = serverData.firmApprovalRemindersConfig;
        if (formData["firstReminderTime"]) {
            const firstReminder = cloneDeep(findReminderConfig("first_reminder", serverData));
            if (firstReminder) {
                firstReminder["minutesFromStart"] = parseInt(formData["firstReminderTime"]);
                serverData["firmApprovalRemindersConfig"][0] = firstReminder;
            }
            
        }

        if (formData["secondReminderTime"]) {
            const reminder = cloneDeep(findReminderConfig("second_reminder", serverData));
            if (reminder) {
                reminder["minutesFromStart"] = parseInt(formData["secondReminderTime"]);
                serverData["firmApprovalRemindersConfig"][1] = reminder;
            }
          
        }

        if (formData["thirdReminderTime"]) {
            const reminder = cloneDeep(findReminderConfig("third_reminder", serverData));
            if (reminder) {
                reminder["minutesFromStart"] = parseInt(formData["thirdReminderTime"]);
                serverData["firmApprovalRemindersConfig"][2] = reminder;
            }
        
        }

        return serverData;
    };

    const getFormData = (id) => {
        if (id in formData) {
            return formData[id];
        }
        return "";
    };

    const resetFormData = () => {
        setFormDirty(false);
        setFormError({});
        setFormData({...defaultFormData});
        setOrignalFormData({...defaultFormData});
    };

    
    const handleAutocompleteElementChange = (elementId, newValue) => {
        if (typeof newValue === 'string') {
        } else if (newValue && newValue.value) {
            formData[elementId] = newValue;
        } else {
            formData[elementId] = "";
        }

        setFormData({ ...formData });
    };

    const handleFormElementChange = (event, id) => {
        const elementId = id || event.target.id;
        if (event.target.type == 'checkbox') {
            formData[elementId] = event.target.checked
        }
        else {
            formData[elementId] = event.target.value;
        }

        setFormDirty(false);
        for (var key in formData) {
            if (formData[key] != "") {
                setFormDirty(true);
            }
        }
        setFormData({ ...formData });

        /**
         * Do general form validation
         */

        validateForm(elementId);
    };

    const validateForm = (elementId) => {
        
        var localFormError = {};

        if (elementId == "name") {
            if (!isFirmNameValid(formData[elementId])) {
                localFormError[elementId] = "Invalid customer name. Only alphanumeric and ~!$%^&*_=+}{'?-. are accepted";
            }

            if (!formData[elementId] || formData[elementId].length == 0) {
                localFormError[elementId] = "Firm name is required";  
            }
            
        }
        
        if (isNewCustomer) {
            if (!formData["defaultApproverFirstName"] || formData["defaultApproverFirstName"].length == 0) {
                localFormError["defaultApproverFirstName"] = "Approver first name is required";  
            }

            if (!formData["defaultApproverLastName"] || formData["defaultApproverLastName"].length == 0) {
                localFormError["defaultApproverLastName"] = "Approver last name is required";  
            }

            if (!formData["defaultApproverEmail"] || formData["defaultApproverEmail"].length == 0) {
                localFormError["defaultApproverEmail"] = "Approver email is required";  
            }
        }
        
        /*
         * Make sure the approver email is one of the valid doamins
         */
        if (getFormData("defaultApproverEmail")) {

            var validEmail = false;
            getFormData("validEmailDomains").forEach((domain) => {
                if (getFormData("defaultApproverEmail").toLowerCase().endsWith(domain.toLowerCase())) {
                    validEmail = true;
                }
            });

            if (!validEmail) {
                localFormError["defaultApproverEmail"] = "The email address must belong to one of the valid domains.";
            }
            else if (!isValidEmail(formData["defaultApproverEmail"])) {
                localFormError["defaultApproverEmail"] = "Invalid email address.";
            }
        
        }

        if (!formData["validEmailDomains"] || (formData["validEmailDomains"].length === 1 &&  formData["validEmailDomains"][0] === "")) {
            localFormError["domain0"] = "At least one email domain is required.";  
        }

        setFormValid(Object.keys(localFormError).filter((key) => localFormError[key].length > 0).length === 0);
        setFormError({...localFormError});
    }

    /*
     * Handlers
     */
    
    const findReminderConfig = (reminderName, customerObject) => {

        if (customerObject.firmApprovalRemindersConfig) {
            return customerObject.firmApprovalRemindersConfig.find((element)=> element.reminder_name == reminderName);
        }
        
        return null;
    };

    const handleAddApprovalCCClick = (e) => {
        formData["approvalCCs"].push("");
        setFormData({ ...formData });
        setFormDirty(true);
    };

    const handleApprovalCCDeleteClick= (index) => {
        formData["approvalCCs"].splice(index,1);
        setFormData({ ...formData });
        setFormDirty(true);
    };

    const handleApprovalCCChange = (event, newValue, index) => {
        if (newValue && newValue.value) {
            formData["approvalCCs"][index] = newValue;
        } 
        setFormData({ ...formData });
    };

    const getApprovalCCValue = (index) => {
        if (formData["approvalCCs"].length > index) {
            return formData["approvalCCs"][index];
        }
       
        return null;
    };

    const handleAddDomainClick = (e) => {
        formData["validEmailDomains"].push("");
        setFormData({ ...formData });
    };

    const handleDomainDeleteClick = (index) => {
        formData["validEmailDomains"].splice(index,1);
        setFormData({ ...formData });
    };

    const handleDomainChange = (event, index) => {
        formData["validEmailDomains"][index] = event.target.value;
        setFormData({ ...formData });
        validateForm("validEmailDomains");
    };

    const handleEditClick = (e) => {
        setEditMode(true);
    };

    const handleCancelEditClick = (e) => {
        if (JSON.stringify(orignalFormData) !== JSON.stringify(formData)) {
            setConfirmationDialogTitle("Discard changes?");
            setConfirmationDialogText("Are you sure you want to discard your work? Unsaved changes will be lost.");
            setConfirmationDialogOpen(true);
        }
        else {
            handleCancelFormOk();
        }
    };

    const handleCancelFormOk = () => {
        setConfirmationDialogOpen(false);

        if (!isNewCustomer) {
            mapServerDataToForm();
            setEditMode(false);
        }
        else {
            navigate('/customer-management');
        }
    };

    const handleCancelFormCancel = () => {
        setConfirmationDialogOpen(false);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const showStatusDialog = (title, text) => {
        setStatusDialogTitle(title);
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleToastClose = () => {
        setToastMessage("");
        setToastOpen(false);
    };

    const showToast = (message, severity) => {
        setToastSeverity(severity);
        setToastMessage(message);
        setToastOpen(true);
    };

    const handleSaveCarrierConfirmOk = () =>{
        const carrier = mapFormDataToServer();
        saveCustomerApi(carrier);
        setSaveConfirmationDialogOpen(false);
    };

    const handleSaveCarrierConfirmCancel = () =>{
        setSaveConfirmationDialogOpen(false);
    };

    const handleSaveCustomerClick = (e) => {
        setSaveConfirmationDialogTitle("Apply changes?");
        setSaveConfirmationDialogText("You updated global customer information that can change the way Exchange delivers demands. Changes will apply immediately.");
        setSaveConfirmationDialogOpen(true);
    };

    /* 
     * hooks
     */
    useEffect(() => {
        mapServerDataToForm();
    }, [precedentUsers, adminUsers]);

    useEffect(() => {
       user && mapServerDataToForm();
    }, [selectedCustomer, user]);

    useEffect(() => {
        if (!isNewCustomer) {
        }
        else {
            setEditMode(true);
            setFormValid(false);
        }
    }, []);

    return (
        <div >
            <Stack spacing={2}>
                <Stack spacing={1}>
                    
                    <Stack direction={"row"}>
                        <Stack direction={"row"} width={"80%"} display="flex" alignItems="left">
                            <Typography variant="h5" color="secondary">
                                Customer details
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} width={"20%"} justifyContent={"flex-end"}>
                            {!editMode  ?
                                <IconButton onClick={handleEditClick}>
                                    <EditOutlined/>
                                </IconButton>
                            :
                            <Stack direction={"row"}>
                                <IconButton onClick={handleCancelEditClick}>
                                    <CloseRounded />
                                </IconButton>

                                <IconButton disabled={!formValid && !saving} onClick={(e)=>{handleSaveCustomerClick(e)}}>
                                    <SaveRounded />
                                </IconButton>
                            </Stack>
                        }                   
                        </Stack>
                    </Stack>

                    {!editMode  ?
                        <Stack spacing={2}>
                            <Stack direction={"row"} spacing={2}>
                                <Stack style={{width: "50%"}} spacing={1}>
                                    <Typography variant="inputLabel" color={"text.secondary"}>Name</Typography>
                                    <Typography variant="body1" >{getFormData("name")}</Typography>
                                </Stack>

                                <Stack style={{width: "50%"}} spacing={1}>
                                    <Typography variant="inputLabel" color={"text.secondary"}>Type</Typography>
                                    <Typography variant="body1" >{mapCustomerTypeToLabel(selectedCustomer.customerType)}</Typography>
                                </Stack>
                            </Stack>

                            <Stack direction={"row"} spacing={2}>
                                <Stack style={{width: "50%"}} spacing={1}>
                                    <Typography variant="inputLabel" color={"text.secondary"}>Account owner</Typography>
                                    <Typography variant="body1" >{getFormData("owner") ? getFormData("owner").label : "No owner configured"}</Typography>
                                </Stack>

                                <Stack style={{width: "50%"}} spacing={1}>
                                    <Typography variant="inputLabel" color={"text.secondary"}>TIN</Typography>
                                    <Typography variant="body1" >{getFormData("tin")}</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={"row"} spacing={2}>
                                <Stack style={{width: "50%"}} spacing={1}>
                                    <Typography variant="inputLabel" color={"text.secondary"}>Customer short label</Typography>
                                    <Typography variant="body1" >{getFormData("customerShortLabel") ? getFormData("customerShortLabel") : "Not configured"}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                      :
                        <>
                        <Stack direction={"row"} spacing={2}>
                            <Stack style={{width: "50%"}} spacing={1}>
                                <TextField label='Name' required={true} fullWidth value={getFormData('name')} 
                                    error={formError["name"]} helperText={formError["name"] || ''}
                                    onChange={(e) => {handleFormElementChange(e,'name')}} />
                            </Stack>

                            <Stack style={{width: "50%"}} spacing={1}>
                                {!isNewCustomer &&
                                    <Typography variant="inputLabel" color={"text.secondary"}>Type</Typography>
                                }

                                {isNewCustomer && 
                                    <Autocomplete
                                        id="customerType"
                                        value={getFormData('customerType')}
                                        onChange={(event, newValue) => {
                                            handleAutocompleteElementChange('customerType', newValue);
                                        }}
                                        forcePopupIcon
                                        autoSelect
                                        handleHomeEndKeys
                                        options={customerTypes}
                                        fullWidth={true}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Regular option
                                            return option.label;
                                        }}
                                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                        renderInput={(params) =>  (
                                            <TextField  {...params} fullWidth label="Customer Type" />
                                        )}
                                    />
                                }

                                {!isNewCustomer &&
                                    <Typography variant="body1">{mapCustomerTypeToLabel(selectedCustomer.customerType)}</Typography>
                                }
                            </Stack>
                        </Stack>

                        <Stack direction={"row"} spacing={2}>
                            <Stack style={{width: "50%"}} spacing={1}>
                                <Autocomplete
                                    value={getFormData('owner')}
                                    onChange={(event, newValue) => {
                                        handleAutocompleteElementChange('owner', newValue);
                                    }}
                                    selectOnFocus
                                    forcePopupIcon
                                    autoSelect
                                    handleHomeEndKeys
                                    id="owner"
                                    options={precedentUsers}
                                    fullWidth={true}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Regular option
                                        return option.label;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                    freeSolo
                                    renderInput={(params) =>  (
                                        <TextField  {...params} fullWidth label="Owner" />
                                    )}
                                />
                            </Stack>

                            <Stack style={{width: "50%"}} spacing={1}>
                                <TextField id="tin" label='TIN' fullWidth value={getFormData('tin')} onChange={(e) => {handleFormElementChange(e,'tin')}}/>
                            </Stack>
                        </Stack>

                        <Stack direction={"row"} spacing={2}>
                            <Stack style={{width: "50%"}} spacing={1}>
                                <TextField label='Customer short label' fullWidth value={getFormData('customerShortLabel')} onChange={(e) => {handleFormElementChange(e,'customerShortLabel')}} />
                            </Stack>
                            <Stack style={{width: "50%"}} spacing={1}>
                            </Stack>
                        </Stack>
                        </>
                    }
                </Stack>
               
                <Stack spacing={2} style={{marginTop:"40px"}}>
                    <Typography variant="h5" color="secondary">
                       Valid email domains
                    </Typography>

                    { getFormData("validEmailDomains") &&
                            getFormData("validEmailDomains").map((domain,index) => {
                                return (
                                    <Stack spacing={1}>
                                        {!editMode &&
                                        <>
                                        <Typography variant="inputLabel" color={"text.secondary"}>Domain name</Typography>
                                        <Stack direction={"row"} spacing={2}>
                                            <AlternateEmail color="primaryActions" />
                                            <Typography variant="body1" color="primary">{domain}</Typography>
                                        </Stack>
                                        </>
                                        }

                                        {editMode &&
                                            <>
                                            <Stack direction={"row"} spacing={2}>
                                                <TextField  id={"domain"+index} label="Domain Name" variant='outlined' fullWidth value={domain}
                                                    onChange={(e) => {handleDomainChange(e,index)}}
                                                    error={formError["domain"+index]} helperText={formError["domain"+index] || ''}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AlternateEmail />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    disabled={isEmailDomainInUse(domain)}
                                                />

                                                <IconButton disabled={isEmailDomainInUse(domain) ||  getFormData("validEmailDomains").length == 1} onClick={(e)=>{handleDomainDeleteClick(index)}}><DeleteRounded/></IconButton>
                                            </Stack>

                                            {isEmailDomainInUse(domain) && 
                                                <Typography variant="body2" color="primary">{domain} cannot be edited or deleted because it's in use.</Typography>
                                            }

                                            </>
                                        }
                            
                                    </Stack>
                                )
                            })
                    }

                    {!editMode && (!getFormData("validEmailDomains")  || getFormData("validEmailDomains").length == 0) &&
                        <Typography variant="body1" color="primary">There are not any domains configured.</Typography>
                    }

                    { editMode &&
                        <Button style={{width: "fit-content"}} variant='text' 
                                onClick={handleAddDomainClick}
                                startIcon={<AlternateEmail />} color='secondary' >Add Domain</Button>
                    }
                </Stack>

                <Stack spacing={2} style={{marginTop:"40px"}}>
                    <Typography variant="h5" color="secondary">
                      Demand emails
                    </Typography>

                   
                    <Stack spacing={1}>
                        <Typography variant="h6" color="secondary">
                        Default approval email
                        </Typography>

                        {!isNewCustomer &&
                            <Stack spacing={1}>
                               

                                {!editMode ?
                                 <>
                                    <Typography variant="inputLabel" color={"text.secondary"}>Email</Typography>
                                    <Typography variant="body1" color="primary">
                                        {getFormData("defaultApprover") ? getFormData("defaultApprover").label : "Not Configured"}
                                    </Typography>
                                    <Typography variant="body2" color="primary">
                                        {getFormData("defaultApprover") ? getFormData("defaultApprover").emailAddress : ""}
                                    </Typography>
                                 </>
                                
                                :
                                    <Autocomplete
                                        value={getFormData('defaultApprover')}
                                        onChange={(event, newValue) => {
                                            handleAutocompleteElementChange('defaultApprover', newValue);
                                        }}
                                        selectOnFocus
                                        forcePopupIcon
                                        autoSelect
                                        handleHomeEndKeys
                                        id="defaultApprover"
                                        options={adminUsers}
                                        fullWidth={true}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Regular option
                                            return `${option.label} (${option.emailAddress})`;
                                        }}
                                        renderOption={(props, option) => <li {...props}>{option.label}&nbsp;<small>({option.emailAddress})</small></li>}
                                        renderInput={(params) =>  (
                                            <TextField  {...params} fullWidth label="Default Approver" />
                                        )}
                                    />
                                }
                            </Stack>
                        }

                        {isNewCustomer && editMode &&
                            <Stack spacing={1}>
                                
                                <Alert severity="info" spacing={2} sx={{ mt: 3, mb: 3 }}>
                                    Create a Law Firm Admin to be the default approval email.  This can be edited when more Law Firm Admins exist.
                                </Alert>
                                    
                                <Stack direction={"row"} spacing={2}>    
                                    <Stack style={{width: "50%"}} spacing={1}>
                                        <TextField required={true} label='First Name' fullWidth value={getFormData('defaultApproverFirstName')} 
                                            error={formError["defaultApproverFirstName"]} helperText={formError["defaultApproverFirstName"] || ''}
                                            onChange={(e) => {handleFormElementChange(e,'defaultApproverFirstName')}} />
                                    </Stack>

                                    <Stack style={{width: "50%"}} spacing={1}>
                                        <TextField required={true}  label='Last Name' fullWidth value={getFormData('defaultApproverLastName')} 
                                            error={formError["defaultApproverLastName"]} helperText={formError["defaultApproverLastName"] || ''}
                                            onChange={(e) => {handleFormElementChange(e,'defaultApproverLastName')}} />
                                    </Stack>
                                </Stack>

                                <Stack direction={"row"} spacing={2}>    
                                    <Stack style={{width: "50%"}} spacing={1}>
                                        <TextField required={true}  label='Email' fullWidth value={getFormData('defaultApproverEmail')} 
                                            error={formError["defaultApproverEmail"]} helperText={formError["defaultApproverEmail"] || ''}
                                            onChange={(e) => {handleFormElementChange(e,'defaultApproverEmail')}} />
                                    </Stack>

                                    <Stack style={{width: "50%"}} spacing={1}>
                                        <Autocomplete
                                            value={getFormData('defaultApproverRole')}
                                            disabled={true}
                                            selectOnFocus
                                            forcePopupIcon
                                            autoSelect
                                            handleHomeEndKeys
                                            id="defaultApproverRole"
                                            options={newApproverRoles}
                                            fullWidth={true}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Regular option
                                                return option.label;
                                            }}
                                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                            renderInput={(params) =>  (
                                                <TextField  {...params} fullWidth label="Role" />
                                            )}
                                        />
                                    </Stack>
                        
                                </Stack>
                            </Stack>
                        }   
                    </Stack>

                    {!isNewCustomer &&
                    <Stack spacing={2}>
                        
                        <Typography variant="h6" color="secondary">
                            Approval CCs
                        </Typography>

                        {getFormData("approvalCCs") &&
                            getFormData("approvalCCs").map((approvalCCUser,index) => {
                                    return (
                                        <Stack spacing={1}>

                                            {!editMode &&
                                                <>
                                                    <Typography variant="inputLabel" color={"text.secondary"}>Email</Typography>
                                                    <Typography variant="body1" color="primary">
                                                       {approvalCCUser?.label}
                                                    </Typography>
                                                    <Typography variant="body2" color="primary">
                                                        {approvalCCUser?.emailAddress}
                                                    </Typography>
                                                </>
                                            }

                                            {editMode &&
                                            <Stack direction={"row"} spacing={2}>
                                                 <Autocomplete
                                                    id={`approvalccemail${index}`}
                                                    value={getApprovalCCValue(index)}
                                                    selectOnFocus
                                                    forcePopupIcon
                                                    autoSelect
                                                    handleHomeEndKeys
                                                    options={adminUsers}
                                                    fullWidth={true}
                                                    onChange={(e, newValue) => {handleApprovalCCChange(e, newValue, index)}}
                                                    getOptionLabel={(option) => {
                                                        // Value selected with enter, right from the input
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }
                                                        // Regular option
                                                        return `${option.label} (${option.emailAddress})`;
                                                    }}
                                                    renderOption={(props, option) => <li {...props}>{option.label}&nbsp;<small>({option.emailAddress})</small></li>}
                                                    renderInput={(params) =>  (
                                                        <TextField  {...params} fullWidth label="Approval CC" />
                                                    )}
                                                />

                                                <IconButton onClick={(e)=>{handleApprovalCCDeleteClick(index)}}><DeleteRounded/></IconButton>
                                            </Stack>
                                            }
                                        </Stack>
                                    )
                                })
                        }

                        {!editMode && getFormData("approvalCCs") && getFormData("approvalCCs").length == 0 &&
                           <Typography variant="body1" color="primary">There are not any approval CCs configured.</Typography>
                        }

                        {editMode &&
                            <Button style={{width: "fit-content"}} variant='text' 
                                onClick={handleAddApprovalCCClick}
                                startIcon={<ContactMailOutlined />} color='secondary' >Add Approval CC</Button>
                        }
                    </Stack>
                    }

                
                    <Stack spacing={1}>
                        <Typography variant="h6" color="secondary">
                        Reminder email schedule
                        </Typography>

                        {!selectedCustomer.firmApprovalRemindersConfig  || selectedCustomer.firmApprovalRemindersConfig.length == 0 && 
                            <Typography variant="body1" color="primary">No reminders configuration for this customer.</Typography>
                        }
                        
                        {selectedCustomer.firmApprovalRemindersConfig  && selectedCustomer.firmApprovalRemindersConfig.length > 0 && 
                            <Stack spacing={2}>
                                <Stack>
                                    <Stack spacing={0}>
                                        { !editMode &&
                                            <>
                                             <Typography variant="inputLabel" color={"text.secondary"}>Time to first reminder</Typography>
                                             <Typography variant="body1" color="primary">{getFormData('firstReminderTime')} minutes</Typography>
                                            </>
                                        }
                                        { editMode &&
                                            <>
                                              <TextField id="firstReminderTime" type='number' label='Time to first reminder' fullWidth value={getFormData('firstReminderTime')} onChange={(e) => {handleFormElementChange(e,'firstReminderTime')}}/>
                                            </>
                                        }
                                        <Typography variant="body2" color="primary">default value = {1440}</Typography>
                                    </Stack>
                                </Stack>

                                <Stack>
                                    <Stack spacing={0}>
                                         { !editMode &&
                                            <>
                                            <Typography variant="inputLabel" color={"text.secondary"}>Time to second reminder</Typography>
                                            <Typography variant="body1" color="primary">{getFormData('secondReminderTime')} minutes</Typography>
                                            </>
                                        }
                                        { editMode &&
                                            <>
                                              <TextField id="secondReminderTime" type='number' label='Time to second reminder' fullWidth value={getFormData('secondReminderTime')} onChange={(e) => {handleFormElementChange(e,'secondReminderTime')}}/>
                                            </>
                                        }
                                        <Typography variant="body2" color="primary">default value = {2880}</Typography>
                                    </Stack>
                                </Stack>

                                <Stack>
                                    <Stack spacing={0}>
                                        { !editMode &&
                                            <>
                                            <Typography variant="inputLabel" color={"text.secondary"}>Time to third reminder</Typography>
                                            <Typography variant="body1" color="primary">{getFormData('thirdReminderTime')} minutes</Typography>
                                            </>
                                        }

                                        { editMode &&
                                            <>
                                              <TextField id="thirdReminderTime" type='number' label='Time to third reminder' fullWidth value={getFormData('thirdReminderTime')} onChange={(e) => {handleFormElementChange(e,'thirdReminderTime')}}/>
                                            </>
                                        }

                                        <Typography variant="body2" color="primary">default value = {4320}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        }
                    </Stack>

                    <Stack spacing={1}>
                        <Typography variant="h6" color="secondary">
                        Custom message
                        </Typography>

                        {!getFormData('customMessage') && 
                            <Typography variant="body1" color="primary">No custom delivery notification message for this customer.</Typography>
                        }
                        
                        {!editMode && getFormData('customMessage')  && 
                            <Stack spacing={2}>
                                {getFormData('customMessage')}
                            </Stack>
                        }

                        {editMode  && 
                            <Stack spacing={2}>
                               <TextField multiline={true} rows={5} style={{width: "100%"}} onChange={(e) => { handleFormElementChange(e, 'customMessage') }} value={getFormData("customMessage")}>
                                </TextField>
                            </Stack>
                        }
                    </Stack>

                    <Stack spacing={1}>
                        <Typography variant="h6" color="secondary">
                        Demand creation
                        </Typography>

                        {!editMode && 
                            <Typography variant="body1" color="primary">{getCreateDemandLabelText(getFormData("createDemandEnabled"))}</Typography>
                        }

                        {editMode &&
                           <FormControlLabel control={<Switch color="secondary" onChange={(e) => {handleFormElementChange(e, 'createDemandEnabled')}} 
                                checked={getFormData('createDemandEnabled') == 1 ? true : false} />} label={getCreateDemandLabelText(getFormData("createDemandEnabled"))} />
                        }

                        {!editMode && 
                            <Typography variant="body1" color="primary">
                                {getUseNotesLabelText(getFormData("useDemandNotesEnabled"))}
                            </Typography>
                        }

                        {editMode && (
                            <FormControlLabel 
                                control={
                                    <Switch 
                                        color="secondary" 
                                        onChange={(e) => {handleFormElementChange(e, 'useDemandNotesEnabled')}} 
                                        checked={getFormData('useDemandNotesEnabled') == 1 ? true : false} 
                                        inputProps={{ 'aria-label': 'Use Notes Enabled', maxLength: 50 }}
                                    />
                                } 
                                label={getUseNotesLabelText(getFormData("useDemandNotesEnabled"))} 
                            />
                        )}
                    </Stack>

                    <Stack spacing={1}>
                        <Typography variant="h6" color="secondary">
                            MRI findings
                        </Typography>

                        {!editMode && 
                            <Typography variant="body1" color="primary">{getDisplayMriFindingsLabelText(getFormData("displayMriFindings"))}</Typography>
                        }

                        {editMode &&
                           <FormControlLabel 
                                control={<Switch color="secondary" onChange={(e) => {handleFormElementChange(e, 'displayMriFindings')}} checked={getFormData('displayMriFindings')} />} 
                                label={getDisplayMriFindingsLabelText(getFormData("displayMriFindings"))} 
                            />
                        }
                    </Stack>

                    <Stack spacing={1}>
                        <Typography variant="h6" color="secondary">
                        Case Data
                        </Typography>

                        {!editMode && getFormData("litifyUrl") &&
                            <Typography variant="body1" color="primary">{getFormData("litifyUrl")}</Typography>
                        }

                        {!editMode && !getFormData("litifyUrl") &&
                            <Typography variant="body1" color="primary">Not configured for this customer</Typography>
                        }


                        {editMode  && 
                              <TextField fullWidth label="Case management URL"
                                onChange={(e) => { handleFormElementChange(e, 'litifyUrl') }} value={getFormData("litifyUrl")}></TextField>
                        }

                    </Stack>
                </Stack>
            </Stack>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
            <ConfirmationDialog handleOk={handleCancelFormOk} handleCancel={handleCancelFormCancel} okButtonText="Discard Changes" cancelButtonText="Cancel" dialogOpen={confirmationDialogOpen} dialogText={confirmationDialogText} dialogTitle={confirmationDialogTitle}></ConfirmationDialog>
            <ConfirmationDialog handleOk={handleSaveCarrierConfirmOk} handleCancel={handleSaveCarrierConfirmCancel} okButtonText="Apply Changes" cancelButtonText="Cancel" dialogOpen={saveConfirmationDialogOpen} dialogText={saveConfirmationDialogText} dialogTitle={saveConfirmationDialogTitle}></ConfirmationDialog>

            <Snackbar
                open={toastOpen}
                autoHideDuration={4000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>{toastMessage}</Alert>
            </Snackbar>
        </div>
    );

}

export default CustomerConfigView;