import { createSlice } from '@reduxjs/toolkit';

const originalUserState = {
  user: {},
  userData: {},
  isAuthenticated: false,
};

export const UserSlice = createSlice({
    name: 'User',
    initialState: originalUserState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },  
    },
});

export const { setUser, setIsAuthenticated } = UserSlice.actions;

export default UserSlice.reducer;