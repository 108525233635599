import React, { useEffect, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuthenticated, setUser } from './redux/slices/userSlice';

export const GlobalHooks = () => {
    const dispatch = useDispatch();
    const [localUser, setLocalUser] = useState({});
    const { user } = useAuthenticator((context) => [context.user]);
    //to do fetch user Data so it is also available in the global state;
    //add that call to RTK Query API; then fetch from there

    useEffect(() => {
        user && setLocalUser(user);
    }, [user]);

    const isAwsUser = Object.keys(localUser).length > 0;
    const userState = useSelector((state) => state.User);
    const reduxUser = userState.user;
    const isReduxUser = Object.keys(reduxUser).length > 0;

    useEffect(() => {

        if (isAwsUser && !isReduxUser) {
            dispatch(setUser(localUser));
            dispatch(setIsAuthenticated(true));
        } else if (!isAwsUser && isReduxUser) {
            dispatch(setUser({}));
            dispatch(setIsAuthenticated(false));
        }
    }, [localUser]);

    return (
        <>
        </>
    )
}

export default GlobalHooks;