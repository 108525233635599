import { createSlice } from '@reduxjs/toolkit';
import { fetchTemplateData, fetchTemplateDocumentUsage, bumpTemplatePin, fetchTemplateSpecificVersionData } from '../thunks/templateData';

const originalTemplateSlice = {
  bumpTemplatePinLoading: false,
  templateFetchLoading: false,
  templateBodyForPinnedVersionLoading: false,
  documentUsageLoading: false,
  documentUsage: {},
  templateBody: {},
  templateBodyForPinnedVersion: {},
};

const TemplateSlice = createSlice({
  name: 'Template',
  initialState: originalTemplateSlice,
  reducers: {
    setTemplate: (state, action) => {
      state.templateBody = action.payload;
    },
    setTemplateForPinnedVersion: (state, action) => {
      state.templateBodyForPinnedVersion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplateData.fulfilled, (state, action) => {
        if (action.payload) {
          state.templateBody = action.payload;
        }
        state.templateFetchLoading = false;
      })
      .addCase(fetchTemplateData.pending, (state) => {
        state.templateFetchLoading = true;
      })
      .addCase(fetchTemplateData.rejected, (state) => {
        state.templateFetchLoading = false;
      })
    
      .addCase(fetchTemplateDocumentUsage.fulfilled, (state, action) => {
        state.documentUsage = action.payload;
        state.documentUsageLoading = false;
      })
      .addCase(fetchTemplateDocumentUsage.pending, (state) => {
        state.documentUsageLoading = true;
      })
      .addCase(fetchTemplateDocumentUsage.rejected, (state) => {
        state.documentUsageLoading = false;
      })

      .addCase(bumpTemplatePin.pending, (state) => {
        state.bumpTemplatePinLoading = true;
      })
      .addCase(bumpTemplatePin.fulfilled, (state) => {
        state.bumpTemplatePinLoading = false;
      })
      .addCase(bumpTemplatePin.rejected, (state) => {
        state.bumpTemplatePinLoading = false;
      })
    
      .addCase(fetchTemplateSpecificVersionData.fulfilled, (state, action) => {
        if (action.payload) {
          state.templateBodyForPinnedVersion = action.payload;
        }
        state.templateBodyForPinnedVersionLoading = false;
      })
      .addCase(fetchTemplateSpecificVersionData.pending, (state) => {
        state.templateBodyForPinnedVersionLoading = true;
      })
      .addCase(fetchTemplateSpecificVersionData.rejected, (state) => {
        state.templateBodyForPinnedVersionLoading = false;
      });
  }
});

export const { setTemplate, setTemplateForPinnedVersion } = TemplateSlice.actions;

export default TemplateSlice.reducer;