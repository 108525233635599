import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMedicals, postMedicals } from '../../api';
import { checkRegeneratePdfStatus } from './documentData';
import { setDemandS3UrlLoading } from '../slices/demandPdfSlice';

export const fetchMedicalsData = createAsyncThunk('Medicals/fetchMedicalsData', async ({ documentId, user }) => {
    return getMedicals(documentId, user)
        .then((response) => response.json())
        .catch(err => err);
});

export const postMedicalsData = createAsyncThunk('Medicals/postMedicalsData', async ({ documentId, medicals, user }, { dispatch }) => {
    dispatch(setDemandS3UrlLoading({
        [documentId]: true
      }));
    return postMedicals(documentId, medicals, user)
        .then((response) => {
            dispatch(checkRegeneratePdfStatus(documentId, user));
            dispatch(fetchMedicalsData({ documentId, user }));
            return response.json()
        })
        .catch(err => err);
});