import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState, createRef } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IconButton, Box, Stack, Collapse, Button, Checkbox, Divider, RadioGroup, FormControl, FormLabel, FormControlLabel, Radio, TextField, Tooltip, Typography, Alert, Card } from '@mui/material';
import { Close, ConnectWithoutContact, InfoOutlined, RecordVoiceOverOutlined, FullscreenRounded, MenuOpenRounded, UndoOutlined } from "@mui/icons-material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MedicalsOverview from './MedicalsComponents/MedicalsOverview';
import MedicalsOverviewPrecedent from './MedicalsComponents/MedicalsOverviewPrecedent';
import { formatDateTime } from './common';
import { getUserApi, getDocumentApi, addContactLogApi } from './api';
import { Transition } from './Transition'
import Title from './CommonComponents/Title';
import StatusDialog from './dialogs/StatusDialog';
import ConfirmationDialog from './dialogs/ConfirmationDialog';
import { theme } from './Theme';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import { Resizable } from 're-resizable';
import ContactLogDetailPanelContent from './ContactLogDetailPanelContent';
import { LoadingButton } from '@mui/lab';
import { searchCarriersApi } from './api';
import { useSelector } from 'react-redux';
import awsExports from './aws-exports';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export const ContactLogAddDialog = (props) => {

    //RTK Query
    const { demandS3UrlLoading, demandS3Url } = useSelector((state) => state.DemandPdf);
    //
    const rootApiUrl = awsExports.ROOT_API_URL;
    const [documentData, setDocumentData] = useState();
    const [documentStatus, setDocumentStatus] = useState();
    const [formData, setFormData] = useState({});
    const [formContactDate, setFormContactDate] = useState();
    const [userData, setUserData] = useState();
    const [saving, setSaving] = useState(false);
    const [contactDateError, setContactDateError] = useState(false);
    const [contacNameError, setContactNameError] = useState(false);
    const [contactDateErrorMessage, setContactDateErrorMessage] = useState();
    const [contactNameErrorMessage, setContactNameErrorMessage] = useState();
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [confirmationDialogText, setConfirmationDialogText] = useState("Are you sure you want to discard your work? Unsaved changes will be lost.");
    const [confirmationDialogTitle, setConfirmationDialogTitle] = useState("Discard changes?");
    const [formDirty, setFormDirty] = useState(false);
    const [demandOverviewExpanded, setDemandOverviewExpanded] = useState(true);
    const [scriptsExpanded, setScriptsExpanded] = useState(false);
    const [contactLogExpanded, setContactLogExpanded] = useState(false);
    const [carriersData, setCarriersData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastOpen, setToastOpen] = useState(false);


    // SidexSide State
    const [documentPanelOpen, setDocumentPanelOpen] = useState(true);
    const [treatmentsMaxWidth, setTreatmentsMaxWidth] = useState('85%');
    const frameRef = createRef();
    const collapsedFrameRef = createRef();
    const scrollFrameRef = createRef();
    const resizableRef = createRef();
    const defaultIframeHeight = window.innerHeight - 150
    const iframeHeight = defaultIframeHeight < 500 ? 500 : defaultIframeHeight;
    const defaultGridHeight = window.innerHeight - 180;
    const gridHeight = defaultGridHeight < 500 ? 500 : defaultGridHeight;

    const { route, signOut, user } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.user
    ]);

    // API Integrations

    const getUser = async () => {
        setUserData(await getUserApi(user));
    };

    const getDocument = async () => {

        const response = await getDocumentApi(props.documentId, user).then((serverResponse) => {
            return serverResponse.json();
        });

        // update the state
        if (response.document) {
            setDocumentData(response.document);
            setDocumentStatus(response.metadata.documentStatus);
        }
    };

    const getDocumentAndClose = async (contactLog) => {
        const response = await getDocumentApi(props.documentId, user).then((serverResponse) => {
            return serverResponse.json();
        });

        setSaving(false);
        props.callback(contactLog, response.document, response.metadata);
    };

    const addContactLogEntry = async (contactLog) => {
        setSaving(true);
        // Set a id for the new entry
        contactLog["contactLogEntryId"] = uuidv4();
        // Call the API to add
        addContactLogApi(contactLog, user)
            .then((response) => {
                if (response.status == 200) {
                    setFormData({});
                    setContactDateError(null);

                    // Reload the document and invoke the close callback.
                    getDocumentAndClose(contactLog);
                }
                else {
                    setSaving(false);
                    showStatusDialog('Add contact log', 'There was an error adding your entry');
                }
            }).finally(() => {

            });
    };

    // Functions from medicals admin viewer to get carrier data (for verified / default delivery chips)
    useEffect(() => {
        if (user) {
            searchCarriers(user, '');
        }
    }, [user]);

    const searchCarriers = async (user, searchText) => {
        const response = await searchCarriersApi(user, searchText);
        response.json()
            .then((data) => {
                setCarriersData(data);
            })
    }


    const handleUpdateDeliveryChannel = (carrierId, deliveryChannel) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(deliveryChannel)
        };

        fetch(rootApiUrl + `/carrier/${carrierId}/deliveryChannel`, settings)
            .then((response) => {
                if (response.status == 200) {
                    setToastMessage("Carrier updated successfully");
                    setToastOpen(true);
                    searchCarriers(user, '');
                }
                else {
                    showStatusDialog("Error updating carrier.");
                }
            }).catch((error) => {
                console.log(error);
            });
    };








    // Helper Functions

    const getFormData = (id) => {
        if (id in formData) {
            return formData[id];
        }
        return "";
    };

    const handleFormElementChange = (event, id) => {
        const elementId = id || event.target.id;
        if (event.target.type == 'checkbox') {
            formData[elementId] = event.target.checked
        }
        else {
            formData[elementId] = event.target.value;
        }

        setFormDirty(false);
        for (var key in formData) {
            if (formData[key] != "") {
                setFormDirty(true);
            }
        }
        setFormData({ ...formData });
    };

    // UI Handlers

    const showStatusDialog = (title, text) => {
        setStatusDialogTitle(title);
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleDemandOverviewExpandClick = () => {
        setDemandOverviewExpanded(!demandOverviewExpanded);
    };

    const handleScriptsExpandClick = () => {
        setScriptsExpanded(!scriptsExpanded);
    };

    const handleContactLogExpandClick = () => {
        setContactLogExpanded(!contactLogExpanded);
    };

    const handleAddContactLogClick = (event) => {

        if (!formContactDate) {
            setContactDateError(true);
            setContactDateErrorMessage("Please enter a contact date and time");
            return;
        }
        else {
            setContactDateError(false);
            setContactDateErrorMessage("");
        }

        if (!formData["adjuster"] || formData["adjuster"].length == 0) {
            setContactNameError(true);
            setContactNameErrorMessage("This is a required field.");
            return;
        }
        else {
            setContactNameError(false);
            setContactNameErrorMessage("");

        }
        const quick_notes = [];
        const carrierAcknowledge = formData["receipt_ack"];
        carrierAcknowledge == "true" && quick_notes.push("receipt_acknowledged_by_carrier");
        getFormData('left_voicemail') && quick_notes.push("left_voicemail");
        getFormData('access_blocked') && quick_notes.push("access_blocked");
        getFormData('new_adjuster') && quick_notes.push("new_adjuster");
        getFormData('new_claim') && quick_notes.push("new_claim");
        getFormData('new_status') && quick_notes.push("new_status");

        const data = {
            "contactDateTs": formContactDate.valueOf(),
            "documentId": documentData.documentId,
            "carrierAcknowledge": carrierAcknowledge,
            "comments": formData["comments"],
            "adjuster": formData["adjuster"],
            "quickNotesSelections": quick_notes,
            "caller": userData.userId
        };
        addContactLogEntry(data)
    };

    const getReceivedDate = () => {
        const statusEvent = props.documentRow.dateReceived;
        if (statusEvent) {
            return formatDateTime(new Date(statusEvent * 1000));
        }
        else {
            return ""
        }
    };


    const handleResetFormOk = () => {
        setFormData({});
        setConfirmationDialogOpen(false);
    };

    const handleResetFormCancel = () => {
        setConfirmationDialogOpen(false);
    };

    const handleResetClick = (event) => {
        if (formDirty) {
            setConfirmationDialogOpen(true);
        }
    };

    const buildCarrierDocumentLink = () => {
        return "https://" + document.location.hostname + "/" + documentData.shortCode;
    };

    // SidexSide Functions

    const handleDocumentPanelClose = (event) => {
        setDocumentPanelOpen(false);
        setTreatmentsMaxWidth(demandS3Url ? '97%' : '100%');
        if (resizableRef.current)
            resizableRef.current.updateSize({ width: '97%', height: '100%' });
    };

    const handleDocumentPanelOpen = (event) => {
        setDocumentPanelOpen(true);
        setTreatmentsMaxWidth('85%');
        if (resizableRef.current)
            resizableRef.current.updateSize({ width: '50%', height: '100%' });
    };

    const onPanelResizeStop = (event, direction, refToElement, delta) => {
        if (documentPanelOpen && (frameRef.current && frameRef.current.offsetWidth / window.innerWidth) < .15) {
            handleDocumentPanelClose();
        }
        else if (!documentPanelOpen && (collapsedFrameRef && collapsedFrameRef.current.offsetWidth / window.innerWidth) > .15) {
            handleDocumentPanelOpen(event);
        }
    };

    const onPanelResize = (event, direction, refToElement, delta) => {
    };

    const scrollToMedicals = (event) => {
        var ref = null;
        if (demandS3Url) {
            ref = scrollFrameRef.current;
        }
        else {
            ref = frameRef.current;
        }

        document.getElementById("diaglogContent").scrollTo({
            top: ref.offsetTop - 55,
            behavior: "smooth"
        });
    };

    // Hooks
    useEffect(() => {
        if (props.dialogOpen) {
            setDocumentData(null);
            setFormData({});
            setFormContactDate(dayjs());
            getUser();
            getDocument();
        }
    }, [props.dialogOpen]);

    useEffect(() => {
        setDocumentPanelOpen(true);
        if (!demandS3Url) {
            setTreatmentsMaxWidth('100%');
            if (resizableRef.current) {
                resizableRef.current.updateSize({ width: '100%', height: '100%' });
            }
        }
        else {
            setTreatmentsMaxWidth('85%');
            if (resizableRef.current)
                resizableRef.current.updateSize({ width: '50%', height: '100%' });
        }
    }, [demandS3Url]);

    return (
        <>
            <Dialog
                open={props.dialogOpen}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
                fullScreen={true}
            >
                <DialogTitle className='dialog-title-blue' sx={{ height: "60px" }} display="flex" alignItems="center">
                    <Stack direction={"row"} justifyContent={"space-between"} sx={{ width: "100%" }}>
                        <Stack spacing={2} alignItems={"center"} direction="row">
                            <IconButton onClick={props.handleClose} aria-label="close" sx={{ '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}>
                                <Close color={'white'} />
                            </IconButton>
                            <Title color={theme.palette.white.main}>Add to Contact Log</Title>
                        </Stack>
                        <Stack direction={"row"} spacing={2}>
                            <Button color='white' onClick={handleResetClick} variant="outlined" startIcon={<UndoOutlined />} >Reset</Button>
                            <LoadingButton loading={saving} disabled={!formContactDate || !getFormData('adjuster')} variant="contained" color='white' onClick={handleAddContactLogClick} sx={{
                                '&:disabled': {
                                    backgroundColor: ("rgba(255,255,255,0.2)")
                                }
                            }}>Save</LoadingButton>
                        </Stack>

                    </Stack>
                </DialogTitle>
                <DialogContent id="diaglogContent" sx={{ my: 0 }}>
                    {documentData && userData ? (
                        <>
                            <Stack direction="column" spacing={2} sx={{ my: 2 }}>
                                <Card>
                                    <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
                                        <Title>Demand overview</Title>
                                        <Stack direction="row" spacing={1}>
                                            <ExpandMore
                                                expand={demandOverviewExpanded}
                                                onClick={handleDemandOverviewExpandClick}
                                            >
                                                <ExpandMoreIcon />
                                            </ExpandMore>
                                        </Stack>
                                    </Stack>

                                    <Collapse in={demandOverviewExpanded} timeout="auto" unmountOnExit>
                                        <MedicalsOverview documentData={documentData} adminView={false} editOverviewCallback={null} />

                                        <Divider sx={{ my: 2 }} />

                                        {/* <MedicalsOverviewPrecedent documentData={documentData} showCarrierPanel={true} carriersData={carriersData} document={documentData} userData={userData} /> */}
                                        <MedicalsOverviewPrecedent documentData={documentData} showCarrierPanel={true} carriersData={carriersData} document={documentData} saveDeliveryChannel={handleUpdateDeliveryChannel} userData={userData} />
                                    </Collapse>
                                </Card>

                                <Card>
                                    <Stack direction="row" display="flex" alignItems="center" justifyContent={"space-between"}>
                                        <Stack direction={"row"} spacing={1}>
                                            <ConnectWithoutContact color="icon" sx={{ height: '30px' }} />
                                            <Title>Contact log</Title>
                                        </Stack>

                                        <Stack direction="row" spacing={1}>
                                            <ExpandMore
                                                expand={contactLogExpanded}
                                                onClick={handleContactLogExpandClick}
                                            >
                                                <ExpandMoreIcon />
                                            </ExpandMore>
                                        </Stack>
                                    </Stack>

                                    <Collapse in={contactLogExpanded} timeout="auto" unmountOnExit>
                                        <div className='collapsible-hr'></div>
                                        {documentData ? (
                                            <ContactLogDetailPanelContent row={documentData} showHeader={false} >
                                            </ContactLogDetailPanelContent>
                                        ) : null}
                                    </Collapse>
                                </Card>

                                <Card>
                                    <Stack direction="row" display="flex" alignItems="center" justifyContent={"space-between"}>
                                        <Stack direction={"row"} spacing={1}>
                                            <RecordVoiceOverOutlined color="icon" sx={{ height: '30px' }} />
                                            <Title>Scripts</Title>
                                        </Stack>

                                        <Stack direction="row" spacing={1}>
                                            <ExpandMore
                                                expand={scriptsExpanded}
                                                onClick={handleScriptsExpandClick}
                                            >
                                                <ExpandMoreIcon />
                                            </ExpandMore>
                                        </Stack>
                                    </Stack>

                                    <Collapse in={scriptsExpanded} timeout="auto" unmountOnExit>
                                        <div className='collapsible-hr'></div>
                                        <Stack spacing={2}>
                                            <Stack spacing={1}>
                                                <Typography variant="h6" color={"primary"} sx={{ pb: 0 }}>Initial contact</Typography>
                                                <Typography variant="body1" color={theme.palette.text.secondary} sx={{ mt: 0 }}>
                                                    Hi, I’m {userData.firstName} {userData.lastName}, calling on behalf of {documentData.sendingFirm.firmName}, to obtain injury adjuster information for one of our demands. Your claim # is {documentData.claimInfo.claimNumber}.
                                                </Typography>
                                            </Stack>
                                            <Stack spacing={1}>
                                                <Typography variant="h6" color={"primary"} sx={{ pb: 0 }}>Follow-up call</Typography>
                                                <Typography variant="body1" color={theme.palette.text.secondary}>
                                                    Hi, I’m {userData.firstName} {userData.lastName}, calling on behalf of {documentData.sendingFirm.firmName}, to confirm receipt of our digital demand. Your claim # is {documentData.claimInfo.claimNumber}.<br /><br />

                                                    <b>ADDITIONAL INFORMATION IF NEEDED →</b> Please feel free to call us at (800) 920-6195 or email us at helpdesk@precedent.com with any feedback or questions. Your dedicated {documentData.sendingFirm.firmName} case manager will be your point of contact for any claim specific questions or concerns.
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Collapse>
                                </Card>
                            </Stack>


                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex'
                                }}
                            >
                                <Resizable ref={resizableRef} onResizeStop={onPanelResizeStop} onResize={onPanelResize} defaultSize={{ width: demandS3Url ? "50%" : "100%", height: "100%" }} minWidth={'25%'} maxWidth={treatmentsMaxWidth}>
                                    <div style={{ paddingRight: '10px', borderRight: demandS3Url ? '2px solid #d4d4d4' : '' }}>
                                        <Card >
                                            <Grid container >
                                                <Grid xs={12} >
                                                    <Stack direction={"row"} alignItems="center" spacing={2}>
                                                        <AddCircleOutlineOutlinedIcon color="icon" />
                                                        <Title>Add to contact log</Title>
                                                        <Tooltip placement="top" title="Click to scroll the medicals full screen" arrow>
                                                            <IconButton onClick={scrollToMedicals}><FullscreenRounded /></IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <div style={{ height: gridHeight + 'px' }}>
                                                <Stack spacing={2} sx={{ mt: 2 }}>

                                                    {props.documentRow.documentStatus === "DocumentReceived" ?
                                                        (
                                                            <Alert severity="success">The carrier acknowledged receipt on {getReceivedDate()}
                                                            </Alert>
                                                        ) : (
                                                            <FormControl>
                                                                <div className='add-contact-label'>Carrier receipt acknowledged:</div>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="ack-buttons-group-label"
                                                                    defaultValue="true"
                                                                    name="ack-buttons-group"
                                                                >
                                                                    <FormControlLabel className='add-contact-label' value="true" control={<Radio onChange={(e) => { handleFormElementChange(e, 'receipt_ack') }}
                                                                        checked={getFormData('receipt_ack') == 'true'} />} label="Yes" />
                                                                    <FormControlLabel className='add-contact-label' value="false" control={<Radio onChange={(e) => { handleFormElementChange(e, 'receipt_ack') }}
                                                                        checked={getFormData('receipt_ack') == 'false'} />} label="No" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        )}

                                                    <Stack direction="column" spacing={2}>
                                                        <TextField size="small" helperText={contactNameErrorMessage} required={true} error={contacNameError} label='Who did you speak with or attempt to contact' onChange={(e) => { handleFormElementChange(e, 'adjuster') }}
                                                            value={getFormData('adjuster')}></TextField>

                                                        <DateTimePicker slotProps={{ textField: { size: 'small' } }} error={contactDateError} required={true} label='Date and time of contact' value={formContactDate}
                                                            onChange={(newValue) => { setFormContactDate(newValue) }}></DateTimePicker>
                                                    </Stack>


                                                    <Stack>
                                                        <Typography variant="h6" color={"secondary"} sx={{ pb: 0 }}>Quick notes</Typography>
                                                        <Stack direction="row" mt={0}>
                                                            <Grid xs={6}>
                                                                <Stack>
                                                                    <FormControlLabel className='add-contact-label' control={<Checkbox onChange={(e) => { handleFormElementChange(e, 'left_voicemail') }}
                                                                        checked={getFormData('left_voicemail') == true} />} label="Left voicemail with carrier" />
                                                                    <FormControlLabel className='add-contact-label' control={<Checkbox onChange={(e) => { handleFormElementChange(e, 'access_blocked') }}
                                                                        checked={getFormData('access_blocked') == true} />} label="Carrier access blocked" />

                                                                    <Stack direction={"row"}>
                                                                        <FormControlLabel className='add-contact-label' control={<Checkbox onChange={(e) => { handleFormElementChange(e, 'new_adjuster') }}
                                                                            checked={getFormData('new_adjuster') == true} />} label="New adjuster info" />
                                                                        <Tooltip arrow title="i.e. Adjuster contact info, reassigned adjuster, etc" placement='top'>
                                                                            <InfoOutlined color='primaryActions' sx={{ mt: 1 }}></InfoOutlined>
                                                                        </Tooltip>
                                                                    </Stack>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid xs={6}>
                                                                <Stack>
                                                                    <Stack direction={"row"}>
                                                                        <FormControlLabel className='add-contact-label' control={<Checkbox onChange={(e) => { handleFormElementChange(e, 'new_claim') }}
                                                                            checked={getFormData('new_claim') == true} />} label="New claim info" />
                                                                        <Tooltip arrow title="New claim number" placement='top'>
                                                                            <InfoOutlined color='primaryActions' sx={{ mt: 1 }}></InfoOutlined>
                                                                        </Tooltip>
                                                                    </Stack>
                                                                    <Stack direction={"row"}>
                                                                        <FormControlLabel className='add-contact-label' control={<Checkbox onChange={(e) => { handleFormElementChange(e, 'new_status') }}
                                                                            checked={getFormData('new_status') == true} />} label="New status update" />
                                                                        <Tooltip arrow title="i.e. Follow-up questions, demand review completed, etc" placement='top'>
                                                                            <InfoOutlined color='primaryActions' sx={{ mt: 1 }}></InfoOutlined>
                                                                        </Tooltip>
                                                                    </Stack>
                                                                </Stack>
                                                            </Grid>
                                                        </Stack>
                                                    </Stack>


                                                    <TextField fullWidth={true} size="small" multiline={true} maxRows={3} label='Additional comments' onChange={(e) => { handleFormElementChange(e, 'comments') }}
                                                        value={getFormData('comments')}></TextField>

                                                </Stack>

                                            </div>
                                        </Card>
                                    </div>
                                </Resizable>

                                {demandS3Url ? (
                                    <div ref={scrollFrameRef} style={{ width: "100%", paddingLeft: '10px' }}>
                                        <Box sx={{ p: 2, display: documentPanelOpen ? 'display' : 'none' }} width={"100%"}>
                                            <Stack direction={"row"} >
                                                <Stack direction={"row"} width={"80%"}>
                                                    <Title>Demand</Title>
                                                </Stack>
                                                <Stack direction={"row"} width={"20%"} justifyContent={'flex-end'}>
                                                    <IconButton onClick={handleDocumentPanelClose}><Close /></IconButton>
                                                </Stack>
                                            </Stack>
                                            <div style={{ marginTop: '10px' }} >
                                                <iframe height={iframeHeight} ref={frameRef} id="documentFrame" src={demandS3Url ? demandS3Url + "#view=fit&toolbar=1" : ""} width={"100%"} />
                                            </div>
                                        </Box>

                                        <Box ref={collapsedFrameRef} sx={{ pl: 1, mt: 2, display: documentPanelOpen ? 'none' : 'display' }} width={"100%"} justifyContent={'flex-end'}>
                                            <IconButton onClick={handleDocumentPanelOpen}><MenuOpenRounded /></IconButton>
                                        </Box>
                                    </div>
                                ) : <div ref={frameRef}></div>}
                            </div>
                        </>
                    ) : (
                        <div></div>
                    )}
                </DialogContent>
            </Dialog>

            <ConfirmationDialog handleOk={handleResetFormOk} handleCancel={handleResetFormCancel} okButtonText="Discard" cancelButtonText="Cancel" dialogOpen={confirmationDialogOpen} dialogText={confirmationDialogText} dialogTitle={confirmationDialogTitle}></ConfirmationDialog>
            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>
        </>
    );
};
