import React from 'react';
import { Alert, Button, CircularProgress } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { bumpTemplatePin } from '../redux/thunks/templateData';
import { useDispatch, useSelector } from 'react-redux';
import { PRECEDENT_VIEW_TYPE } from '../common-view-types';
import { setDocumentFilesLoading } from '../redux/slices/documentSlice';
import { getDocumentFilesApi } from '../api';
import { setDocumentFiles } from '../redux/slices/demandDomSlice';
import { setToast } from '../redux/slices/globalToastSlice';
import { setDemandS3UrlLoading } from '../redux/slices/demandPdfSlice';
import pdfApi from '../services/pdf';

const excludedDocumentStatuses = ['DocumentApproved', 'DocumentSubmitted', 'DocumentReceived', 'DocumentArchived'];

export const shouldDisplayAlert = (templateBody, templateBodyForPinnedVersion, documentMetaData) => {
    if (!templateBody?.live || !templateBodyForPinnedVersion) return false; // if something failed with loading of templates, don't show the alert

    if (!documentMetaData || excludedDocumentStatuses.includes(documentMetaData.documentStatus)) return false; // check excluded statuses

    if (documentMetaData?.demandTemplateId !== templateBody.templateId) return false; // if the metadata template ID doesnt match the template ID, don't show the alert

    const templateVersion = templateBody ? parseInt(templateBody.version, 10) : NaN;
    const demandTemplatePinnedVersion = templateBodyForPinnedVersion ? parseInt(templateBodyForPinnedVersion.version, 10) : NaN;
    return !Number.isNaN(templateVersion) && !Number.isNaN(demandTemplatePinnedVersion) && templateVersion !== demandTemplatePinnedVersion;
};

const TemplateUpdateAlert = ({ user, templateBody, pinnedTemplateBody, documentMetaData, viewType }) => {
    const documentId = documentMetaData.documentId;
    const dispatch = useDispatch();
    const { bumpTemplatePinLoading, templateFetchLoading } = useSelector((state) => state.Template);
    const { documentDataLoading } = useSelector((state) => state.Document);

    const handleUpdateTemplateClick = async () => {
        dispatch(bumpTemplatePin({ documentId: documentMetaData.documentId, user, templateId: templateBody.templateId }));

        dispatch(setDemandS3UrlLoading({
            [documentId]: true
        }));

        getDocumentFilesApi(documentMetaData.documentId, user)
            .then(response => response.json())
            .then(data => {
                dispatch(  //use force refetch to invalidate the cache
                    pdfApi.endpoints.getPdfRegenerationStatus.initiate(documentId, { forceRefetch: true })
                );
                dispatch(setDocumentFiles(data));
                dispatch(setDocumentFilesLoading(false));
            })
            .catch(error => {
                console.log('error', error);
                dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error updating demand' }));
                dispatch(setDocumentFilesLoading(false));
            });
    };

    const templateVersion = templateBody ? parseInt(templateBody.version, 10) : NaN;
    const demandTemplatePinnedVersion = documentMetaData ? parseInt(documentMetaData.demandTemplatePinnedVersion, 10) : NaN;
    const isAnythingLoading = bumpTemplatePinLoading || templateFetchLoading || documentDataLoading;

    return (
        <Alert
            icon={<WarningAmberOutlinedIcon fontSize="inherit" />}
            severity="warning"
            action={

                <Button
                    color="primary"
                    size="small"
                    onClick={handleUpdateTemplateClick}
                    disabled={isAnythingLoading}
                >
                    {isAnythingLoading ? <CircularProgress size={20} /> : 'Update template'}
                </Button>
            }
        >
            New version of <strong>{pinnedTemplateBody.templateName}</strong> available {viewType === PRECEDENT_VIEW_TYPE ? `(current: v${demandTemplatePinnedVersion}, latest: v${templateVersion})` : ''}
        </Alert>
    );
};

export default TemplateUpdateAlert;